import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MainService } from 'src/app/services/main.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import Swal from "sweetalert2";
declare var $;
declare var UIkit;
@Component({
  selector: 'app-admin-documents',
  templateUrl: './admin-documents.component.html',
  styleUrls: ['./admin-documents.component.scss']
})
export class AdminDocumentsComponent implements OnInit {
  loading = false;
  constructor(
    public main: MainService,
    private http:HttpService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

}
