import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ChatHistoryComponent } from './components/chat-history/chat-history.component';
import { LikedComponent } from './components/liked/liked.component';
import { BlockedComponent } from './components/blocked/blocked.component';
import { QoinComponent } from './components/qoin/qoin.component';
import { ItemsComponent } from './components/items/items.component';
import { TermsComponent } from './components/terms/terms.component';
import { SearchComponent } from './components/search/search.component';
import { AdminIncomesComponent } from './components/admin-incomes/admin-incomes.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminDocumentsComponent } from './components/admin-documents/admin-documents.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AdminProfilesComponent } from './components/admin-profiles/admin-profiles.component';
import { AdminProfileComponent } from './components/admin-profile/admin-profile.component';
import { AdminReportsComponent } from './components/admin-reports/admin-reports.component';
import { GameHangmanComponent } from './components/game-hangman/game-hangman.component';
import { AdminDataclassifyComponent } from './components/admin-dataclassify/admin-dataclassify.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'chat-history',
    component: ChatHistoryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'liked',
    component: LikedComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'blocked',
    component: BlockedComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'search',
    component:  SearchComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'qoin',
    component: QoinComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'items',
    component: ItemsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-incomes',
    component: AdminIncomesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'game-hangman',
    component: GameHangmanComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-profiles',
    component: AdminProfilesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-profile/:uid',
    component: AdminProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-documents',
    component: AdminDocumentsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-reports',
    component: AdminReportsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-dataclassify',
    component: AdminDataclassifyComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'agreement',
    component: AgreementComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent
  },
  { path: '**', redirectTo:'/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }