import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot}  from '@angular/router';
import { UserService } from './user.service';
import { HttpService } from './http.service';
import { ErrorHandlerService } from './error-handler.service';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private router: Router,
    private http: HttpService,
    private errorHandler: ErrorHandlerService,
    private main: MainService,
    private user: UserService
  ) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      let refresh = await this.http.get("refresh", true);
      if (refresh.success && refresh.data){
        this.user.saveUserData(refresh.data);
        return true;  
      } else {
        await this.main.notification(refresh.msg, "error", "Амжилтгүй");
        this.router.navigate(["/login"]);
        return false;
      }
    } catch(ex){
      await this.errorHandler.handle(ex);
      window.location.href = "/login";
      return false;
    }
  }
}
