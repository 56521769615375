import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http:HttpClient
  ) { }
  
  get(actionUrl: string, isAdmin=false){
    let reqUrl = (isAdmin?environment.adminApi:environment.userApi) + actionUrl;
    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/json');
    let reqOptions = {headers:header, withCredentials:true};
    return new Promise<any>((resolve, reject)=>{
      this.http
      .get<any>(reqUrl, reqOptions)
      .subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    })
  }

  post(actionUrl: string, data:object, isAdmin=false){
    let reqUrl = (isAdmin?environment.adminApi:environment.userApi) + actionUrl;
    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/json');
    //let reqOptions = {headers:header, withCredentials:true, observe: "response" as 'body'};
    let reqOptions = {headers:header, withCredentials:true };
    return new Promise<any>((resolve,reject)=>{
      this.http.post<any>(reqUrl, data, reqOptions)
      .subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    })
  }
}
