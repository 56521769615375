import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(
    private main: MainService,
    private http:HttpService,
    private errorHandler: ErrorHandlerService
  ) { }
  public template = this.main.agreement;

  ngOnInit(): void {
    
  }

  // async getAgreement(){
  //   try{
  //     let agreement = await this.http.get("agreement");
  //     if (agreement.success){
  //       this.template = agreement.data;
  //     } else {
  //       this.main.notification("Үйлчилгээний нөхцөл татаж чадсангүй та дахин оролдоно уу", "warning");
  //     }
  //   } catch(ex){
  //     this.errorHandler.handle(ex);
  //   }
  // }
}
