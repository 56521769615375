import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  loading = false;
  avatars = [];
  others = [];
  myItems=[];
  profile;
  constructor(
    public main: MainService,
    private http: HttpService,
    private user: UserService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.profile = this.user.getUserData();
    this.loading = true;
    this.getItems();
  }

  ngAfterViewInit() {
    this.main.sidebarInitSmallDisplay();
  }

  async getItems(){
    try {
      let items = await this.http.get("market/items");
      if(items.success){
        items.data.forEach(element => {
          if(element.type=="avatar"){
            this.avatars.push(element);
          } else {
            this.others.push(element);
          }
        });
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async getMyItems(){
    try {
      let items = await this.http.get("profile/myItems");
      if(items.success){
        this.myItems = items.data;
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  checkIfBought(itemId){
    return this.profile.items.find(srch=>{return srch == itemId})?true:false;
  }

  async buy(itemId, price){
    try {
      if(!this.checkIfBought(itemId)){
        let confirm = await this.main.confirmation("Танаас "+price+" Qoin хасагдах болно", "Худалдаж авах");
        if(confirm.value){
          let buy = await this.http.post("market/buy",{item: itemId});
          if(buy.success){
            this.profile = buy.data;
            this.user.saveUserData(this.profile);
            await this.main.notification("Худалдан авалт амжилттай, Идэвхижүүлэхийг хүсвэл энэ аватар дээр дахин дарж идэвхижүүлээрэй", "success");
          } else {
            await this.main.notification(buy.msg, "warning");
          }
        }
      } else {
        let confirm = await this.main.confirmation("Энэ аватарыг үндсэн аватар болгох уу?", "Аватар солих");
        if(confirm.value){
          let setAvatar = await this.http.post("profile/setAvatar",{item: itemId});
          if(setAvatar.success){
            await this.main.notification("Аватар амжилттай солигдлоо", "success");
            window.location.reload();
          } else {
            await this.main.notification(setAvatar.msg, "warning");
          }
        }
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }
}
