import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { MainService } from './main.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public myPartners = [];
  constructor(
    private http:HttpService,
    private main: MainService
  ) { }

  saveUserData(data, option='profile'){
    if(environment.production){
      sessionStorage.setItem(option, this.main.encrypt(JSON.stringify(data)));
    } else {
      sessionStorage.setItem(option, JSON.stringify(data));
    }
  }

  delUserData(option='profile'){
    sessionStorage.removeItem(option);
  }

  getUserData(option='profile') {
    if(environment.production){
      return sessionStorage.getItem(option)?JSON.parse(this.main.decrypt(sessionStorage.getItem(option))):null;
    } else {
      return sessionStorage.getItem(option)?JSON.parse(sessionStorage.getItem(option)):null;
    }
  }

  validateProfile(profile){
    if(!profile.agreement){
      return "agreement";
    }
    if(!profile.username){
      return "profile";
    }
    if(!profile.phoneNumber){
      return "profile";
    }
    if(!profile.phoneNumber_verified){
      return "profile";
    }
    if(!profile.registerNumber){
      return "profile";
    }
    if(!profile.birthDate){
      return "profile";
    }
    if(!profile.gender){
      return "profile";
    }
    if(profile.passportRequired){
      return "passport";
    }
    if(profile.passportUploaded){
      return "passport";
    }
    // if(!profile.email){
    //   return "profile";
    // }
    // if(!profile.email_verified){
    //   return "profile";
    // }
    return null;
  }

  async getUserProfile(uid){
    try {
      let info = await this.http.post("getUserProfile",{uid:uid}, true);
      if (info.success){
        return info.data;
      } else {
        return null;
      }
    } catch(ex){
      return null;
    }
  }

  async fetchPublicInfo(uid, forceReload=false){
    try {
      let info = await this.http.post("getPublicInfo",{uid:uid, forceReload:forceReload});
      if (info.success){
        return info.data;
      } else {
        return null;
      }
    } catch(ex){
      return null;
    }
  }

  async getPublicInfos(profiles){
    for(let uid of profiles){
      if(!this.myPartners[uid] || (this.myPartners[uid] && !this.myPartners[uid].username) || (this.myPartners[uid] && this.myPartners[uid].avatar)){
        let partnerInfo = await this.fetchPublicInfo(uid);
        if(this.myPartners[uid]){
          this.myPartners[uid].username = partnerInfo.username;
          this.myPartners[uid].avatar = partnerInfo.avatar;
        } else {
          this.myPartners[uid]={username: partnerInfo.username, avatar: partnerInfo.avatar, notification: false};
        }
      }
    }
  }

  logout(){
    this.delUserData("profile");
    this.delUserData("configs");
    this.delUserData("token");
    window.location.href=environment.loginRedirectUrl;
  }
  
}
