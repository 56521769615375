import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UserService } from "../../services/user.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {
  template;
  profile;
  constructor(
    public main:MainService,
    private http:HttpService,
    private errorHandler:ErrorHandlerService,
    private user: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAgreement();
  }

  ngAfterViewInit() {
    this.main.sidebarInitSmallDisplay();
    this.profile = this.user.getUserData();
  }

  async getAgreement(){
    try{
      let agreement = await this.http.get("agreement");
      if (agreement.success){
        this.template = agreement.data;
      } else {
        this.main.notification("Үйлчилгээний нөхцөл татаж чадсангүй та дахин оролдоно уу", "warning");
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }

  async agreement(option) {
    if (option == "agree") {
      let update = await this.http.post("profile/update", {agreement:true, agreementDate: Date.now()});
      if(update.success){
        this.user.saveUserData(update.data);
        this.router.navigate(["home"]);
      } else {
        await this.main.notification(update.msg, "warning");
      }
    } else {
      this.http.get("logout");
      this.user.delUserData("profile");
      this.user.delUserData("configs");
      this.router.navigate(["login"]);
    }
  }

}
