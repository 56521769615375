<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div *ngIf="template" class="uk-container uk-container-large">
            <div [innerHtml]="template"></div>
            <p *ngIf="!profile || !profile.agreement" class="uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" (click)="agreement('dont')" type="button">Үгүй</button>
                <button style="margin-left:10px" class="uk-button uk-button-primary" type="button" (click)="agreement('agree')">Зөвшөөрч байна</button>
            </p>
        </div>
    </div>
</div>