import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private router:Router
  ) { }

  async handle(err){
    if(err.status && err.status == 500) {
      let traceNo = "unknown";
      if (err.error && err.error.error && err.error.error.trace){
        traceNo = err.error.error.trace;
      }
      await this.notification("Cистемд алдаа гарлаа: "+traceNo, "error");
    } else if (err.status && err.status == 401) {
      await this.notification("Системд дахин нэвтрэнэ үү", "warning");
      this.router.navigate(["login"]);
    } else {
      if(err.error && err.error.msg){
        await this.notification(err.error.msg, "warning");
      } else if (err.error && err.error.errors && err.error.errors.length) {
        await this.notification(err.error.errors[0].msg?err.error.errors[0].msg + " : " +err.error.errors[0].param: "Системд алдаа гарлаа", "error");
      } else {
        if(err.message){
          await this.notification(err.message?err.message:err.toString(), "error");
        } else if (err.error) {
          await this.notification(err.error?err.error:err.toString(), "error");
        } else {
          await this.notification(err.toString(), "error");
        }
      }
    }
  }

  async notification(text, icon) {
    await Swal.fire({
      icon: icon,
      title: icon=="error"?"Алдаа":"Мэдэгдэл",
      text: text
    })
  }
}
