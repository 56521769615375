<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <div class="uk-card uk-card-default uk-card-body uk-width-1-1">
                <h3 class="uk-card-title">Хэрэглэгчийн нэрээх хайх</h3>
                <form (submit)="search()">
                    <div uk-form-custom="target: true" class="uk-width-1-1 uk-width-4-5@s">
                        <input class="uk-input uk-width-1-1" name="search" [(ngModel)]="srchWord" type="text" placeholder="хайх нэрээ бичнэ үү">
                    </div>
                    <button class="uk-button uk-button-primary uk-width-1-1 uk-width-1-5@s" [disabled]="loading" type="submit">Хайх</button>
                </form>
            </div>
            <div *ngIf="srchResult" class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-top">
                <div class="uk-grid">
                        <div class="uk-width-1-1 uk-width-3-5@s"><img src="{{main.getAvatarPath(srchResult.avatar)}}" alt="Avatar" class="avatar" style="margin-right:10px"><b>{{srchResult.username}}</b></div>
                        <div class="uk-width-1-1 uk-hidden@s" style="height:10px; margin-top:10px"></div>
                        <div class="uk-width-1-2 uk-width-1-5@s"><button class="uk-button uk-button-primary uk-width-1-1 button-border-radius" (click)="like(srchResult.uid)" [disabled]="loading">like</button></div>
                        <div class="uk-width-1-2 uk-width-1-5@s"><button class="uk-button uk-button-secondary uk-width-1-1 button-border-radius" (click)="block(srchResult.uid)" [disabled]="loading">block</button></div>
                </div>
            </div>
        </div>
    </div>
</div>