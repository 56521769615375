<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul class="uk-tab" data-uk-tab="{connect:'#hangmanTabs'}">
                <li class="uk-active"><a>Questions</a></li>
                <li><a>Add new Questions</a></li>
            </ul>
            <ul id="hangmanTabs" class="uk-switcher">
                <li uk-grid class="uk-child-width-1-1">
                    <div>
                        <div class="uk-card uk-card-default uk-card-body">

                        </div>
                    </div>
                </li>
                <li uk-grid class="uk-child-width-1-1">
                    <div class="uk-card uk-card-default uk-card-body">
                        <form class="uk-form-stacked" (submit)="uploadQuestions($event)">
                            <div class="uk-margin">
                                <label>Асуулт хариултын xls file аа оруулна уу</label>
                                <div class="uk-margin" uk-margin>
                                    <div uk-form-custom="target: true">
                                        <input type="file" id="uploader">
                                        <input class="uk-input uk-form-width-medium" type="text" placeholder="Асуулт хариултын xls file аа оруулна уу" disabled>
                                    </div>
                                    <button class="uk-button uk-button-default" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>