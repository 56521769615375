import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $;
declare var window;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isMobile;
  //private currentRoute;
  constructor(
    public main:MainService,
    private http: HttpService,
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if(this.main.isMobile){
      this.main.sidebarToggle(false);
    }
  }

  ngAfterViewInit(){
    if(window.FB){
      if(window.location.pathname.startsWith("/chat")){
        window.FB.CustomerChat.hide();
      } else {
        window.FB.CustomerChat.show(false);
      }   
    }
  }

  logout() {
    this.http.get("logout");
    this.user.delUserData("profile");
    this.user.delUserData("configs");
    this.router.navigate(["login"]);
  }
}