import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-profiles',
  templateUrl: './admin-profiles.component.html',
  styleUrls: ['./admin-profiles.component.scss']
})
export class AdminProfilesComponent implements OnInit {
  loading = false;
  searchString;
  srchOption="username";
  srchResult;
  constructor(
    public main: MainService,
    private http: HttpService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  async search(){
    if(this.searchString){
      try {
        this.loading = true;
        this.srchResult = null;
        let srch = await this.http.post("admin/searchProfile", {srch: this.searchString, option:this.srchOption});
        if(srch.success){
          this.searchString = null;
          this.router.navigate(["/admin-profile/"+srch.data]);
        } else {
          this.main.notification(srch.msg, "warning");
        }
        this.loading = false;
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

}
