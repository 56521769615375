import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import readXlsxFile from 'read-excel-file';
import { HttpService } from 'src/app/services/http.service';
declare var $;
@Component({
  selector: 'app-game-hangman',
  templateUrl: './game-hangman.component.html',
  styleUrls: ['./game-hangman.component.scss']
})
export class GameHangmanComponent implements OnInit {

  constructor(
    public main:MainService,
    private http:HttpService
  ) { }

  ngOnInit(): void {
  }

  async uploadQuestions(ev){
    ev.preventDefault();
    const uploadForm = $("#uploader");
    if (uploadForm.prop('files') && uploadForm.prop('files')[0]) {
      try {
        const file = uploadForm.prop('files')[0];
        const xlsx = await readXlsxFile(file);
        if(xlsx.length){
          console.log(xlsx)
          let res = await this.http.post("uploadHangmanQuestions", {data:xlsx});
          console.log(res);
        }
      } catch(ex){
        this.main.notification("Файл уншхад алдаа гарлаа, хуучин XLS файл бол эхлээд XLSX болгож байгаад уншуулна уу", "error");
      }
    }
  }
}
