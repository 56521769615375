import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public profile;
  constructor(
    private user:UserService,
    public main: MainService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.profile = this.user.getUserData("profile");
  }

  goToChat(){
    this.router.navigate(["/chat"]);
  }

  isTherePrivateMsg(){
    let privateMsg = this.user.getUserData("unreadmsgs");
    if(privateMsg){
      return privateMsg.length;
    } else {
      return 0;
    }
  }

  amILuckyStar(avatarId){
    let luckyStar =  this.user.getUserData("luckyStar");
    if(luckyStar && this.profile._id === luckyStar){
      if(environment.production){
        return "878266535990608";
      } else {
        return "882946952184781";
      }
    } else {
      return avatarId;
    }
  }


}
