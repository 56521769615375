import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-blocked',
  templateUrl: './blocked.component.html',
  styleUrls: ['./blocked.component.scss']
})
export class BlockedComponent implements OnInit {
  blockedList = [];
  loading = true;
  constructor(
    public main: MainService,
    private http: HttpService,
    private errorHandler: ErrorHandlerService,
    public user: UserService
  ) { }

  ngOnInit(): void {
    this.getBlocked();
  }

  ngAfterViewInit() {
    this.main.sidebarInitSmallDisplay();
  }

  async getBlocked(){
    try {
      let blocked = await this.http.get("profile/getBlocked");
      if(blocked.success){
        this.blockedList = blocked.data;
        this.getPublicInfos();
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  getPublicInfos(){
    let uids = this.blockedList.map(ret=>{return ret.blockedProfile});
    this.user.getPublicInfos(uids);
  }

  async delete(id){
    let confirm = await this.main.confirmation("Та уг хүнийг блок жагсаалтаас устгахдаа итгэлтэй байна уу");
    if (confirm.value){
      try {
        this.loading = true;
        let blocked = await this.http.post("profile/delBlocked", {id: id});
        if(blocked.success){
          window.location.reload();
        }
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

}
