import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as moment from 'moment';
import { HttpService } from './http.service';
import {environment} from '../../environments/environment';
import { Base64 } from 'js-base64';
import * as numeral from 'numeral';

declare var $;
declare var UIkit;

@Injectable({
  providedIn: 'root'
})
export class MainService {
  public isMobile = (window.navigator.userAgent).includes("Mobile");
  public configs = this.getConfigs();

  public agreement = `<div class="uk-width-1-1 uk-text-center"><h3>Үйлчилгээний нөхцөл / Нууцын бодлого</h3></div>
  <ol>
      <li>
          <h4>Ерөнхий заалт</h4>
          <p>1) Quizmo нь чат, тоглоом гэх мэт төрөл бүрийн зугаатай үйлчилгээнүүдийг өөртөө шингээсэн залуусыг цагаа зөв соёлтой өнгөрөөхөд чиглэсэн интертэйнмент систем юм.</p>
          <p>2) Уг үйлчилгээний нөхцөл нь Quizmo системийн бүх үйлчилгээнүүдэд ижил үйлчилнэ</p>
          <p>3) Та уг үйлчилгээний нөхцөлийг зөвшөөрсөнөөр Quizmo системийн хэрэглэгч болно</p>
      </li>
      <li>
          <h4>Quizmo Chat</h4>
          <p>1) Quizmo Chat нь Facebook болон бусад төрлийн чатын системүүд дээр тулгуурлан ажилладаг санамсаргүй тохирлоор чатлах дэд систем юм</p>
          <p>2) Quizmo Chat ашиглан чатлаж байгаа үед таны чатлаж байгаа хүн таныг хэн болохыг мэдэхгүй, та ч бас харилцаж байгаа хүнээ хэн болохыг мэдэх боломжгүй</p>
          <p>3) Quizmo Chat нь бүрэн автомат (хөндлөнгийн хүний оролцоогүй) чатбод бөгөөд, харилцан ярианаас бусад бүх мессежүүдийг програмаас автоматаар өгч байдаг болно</p>
          <p>4) Quizmo Chat нь таны харилцан ярианы бичлэгүүдийг нөөцлан хадгалдаг</p>
          <p>5) Quizmo Chat ашиглаж байхад танд системээс зар сурталчилгааны шинжтэй мэдээлэл илгээх боломжтой</p>
      </li>
      <li>
          <h4>Хориглох зүйлс</h4>
          <p>1) Та чат ашиглаж байхдаа өөрийн хувийн мэдээллийг (утасны дугаар, өөрийн жинхэнэ нэр, хаяг гэх мэт) бусдад тараахыг хориглоно. 
          Бусдад хувийн мэдээллээ тарааснаас болж үүсэх асуудалд Quizmo хариуцлага хүлээхгүй</p>
          <p>2) Та чат ашиглаж байхдаа бүх төрлийн хараалын үг хэлэх, харилцаж буй хүнээ доромжлохыг хатуу хориглоно</p>
          <p>3) Та чатлаж буй хүнээ сэтгэлзүйн болон бэлгийн дарамтанд оруулах бүх үйлдлүүдийг хатуу хориглоно</p>
          <p>4) Бүх төрлийн садар самуунд уруу татсан өнгө аястай мессеж, зураг, видео, аудио болон бусад бүх төрлийн аттачмент илгээхийг хатуу хориглоно</p>
          <p>5) Архи, тамхины сурталчилгаа хийх, мөн тэдгээрийн хэрэглээний талаар мессеж болон бусад төрлийн аттачмент илгээхийг хатуу хориглоно</p>
          <p>6) Бүх төрлийн мансууралах бодис, хүний наймаа, биеэ үнэлэлт, залилан мөн Монгол улсын хуулинд заасан бүх төрлийн гэмт хэргийн зорилгоор чат болон бусад системүүдийг ашиглахыг хатуу хориглоно</p>
          <p>7) Та дээрх хориглох заалтуудын аль нэгийг зөрчвөл таныг системээс бүр мөсөн блоклож, шаардлагатай тохиолдолд таны харилцан ярианы бичлэг болон, тантай холбоотой хувийн мэдээллийг хууль хүчний байгуулагад хүлээлгэн өгөх болно</p>
          <p>8) Та дүрэм зөрчсөнөөс болж эрхээ хязгаарлуулсан бол тухайн үеийн таны дансанд байгаа мөнгөн үлдэгдэлийг буцаан олгох боломжгүй</p>
      </li>
      <li>
          <h4>Хувийн мэдээллийн нууц хадгалах тухай</h4>
          <p>1) Таны хувийн мэдээлэл танаас өөр хэрэглэгчид харагдахгүй</p>
          <p>2) Системд хадгалагдсан таны хувийн мэдээллийг бид бизнесийн зорилгоор бусдад шилжүүлэхгүй</p>
          <p>3) Системд хадгалагдсан таны хувийн мэдээллийг (гар утасны дугаар, И-Мэйл хаяг гэх мэт) бид өөрсдийн системийн сайжруулалт болон, танд шаардлагатай мэдээлэл түгээх зорилгоор ашиглаж болно.</p>
          <p>4) Бид шаардлагатай тохиолдолд хууль хүчний байгууллагыг мэдээллээр хангах үүрэгтэй</p>
          <p>5) Та санаатайгаар хувийн мэдээллээ буруу оруулж үйлчилгээ авсан тохиолдолд бид таны профайлыг түгжих эрхтэй ба дансан дахь Qoin буцаан олгохгүй</p>
      </li>
      <li>
          <h4>Төлбөр тоцоо</h4>
          <p>1) Quzimo нь үнэ төлбөргүй үйлчилгээ юм</p>
          <p>2) Quizmo нь Quest Ecosystem -н оролцогч бөгөөд Quizmo дээрх нэмэлт тохиргоо, үйлчилгээнүүд нь Quest Coin-н гишүүнчлэлийн журмын дагуу идэвхижэнэ</p>
          <p>2) Quizmo нь Quest Coin -г зарж борлуулдаггүй. Quest Coin нь өөрөө урамшууллын оноо, гишүүнчлэлийн баталгаажуулалт болон бусад Quest экосистемийн оролцогчдоор дамжуулан тараагддаг мөн хүмүүсийн гар дээр буй Quest Coin ууд нь төвлөрсөн бус нээллтэй арилжааны биржүүдээр бусад крипто ассетуудаар солилцох зарчмаар арилжаалагддаг</p>
      </li>
      <li>
          <h4>Үйлчилгээ цуцлах / Профайл устгах</h4>
          <p>1) Quizmo нь автоматаар 6 сарын дараа идэвхигүй профайлуудыг түүж устгадаг</p>
          <p>2) Системийг хэрэглэхгүй 6 сар болоход профайл болон холбоотой хувийн мэдээллүүд устаж үйлчилгээний гэрээ цуцлагдна</p>
      </li>
  </ol>
  <p>Та дээрх бүх заалтуудыг хүлээн зөвшөөрч байж чат болон бусад системийг ашиглах боломжтой болох тул дээрх заалтуудын анхааралтай уншина уу. чат хэрэглэгч болон Quizmo чатын хооронд ямар нэгэн маргаан үүсвэл уг үйлчилгээний нөхцлийг баримт болгон маргааныг шийдвэрлэх болно</p>
  `;

  constructor(
    private http: HttpService
  ) { }

  getConfigs(){
    if(environment.production){
      return sessionStorage.getItem("configs")?JSON.parse(this.decrypt(sessionStorage.getItem("configs"))):null;
    } else {
      return sessionStorage.getItem("configs")?JSON.parse(sessionStorage.getItem("configs")):null;
    }
  }

  sidebarToggle(toogle) {
    var sidebar = $('#sidebar');
    var padder = $('.content-padder');
    if( toogle ) {
        sidebar.css({'display': 'block', 'x': -300});
        sidebar.transition({opacity: 1, x: 0}, 250, 'in-out', function(){
            sidebar.css('display', 'block');
        });
        if( $( window ).width() > 960 ) {
            padder.transition({marginLeft: sidebar.css('width')}, 250, 'in-out');
        }
    } else {
        sidebar.css({'display': 'block', 'x': '0px'});
        sidebar.transition({x: -300, opacity: 0}, 250, 'in-out', function(){
            sidebar.css('display', 'none');
        });
        padder.transition({marginLeft: 0}, 250, 'in-out');
    }
  }

  sideBartoggler(){
    var sidebar = $('#sidebar');
    if( sidebar.css('x') == '-300px' || sidebar.css('display') == 'none' ) {
        this.sidebarToggle(true)
    } else {
        this.sidebarToggle(false)
    }
  }

  sidebarInitSmallDisplay(){
    if($( window ).width() < 960) {
      $("#sidebar").hide();
    }
  }

  mobileSideBarHide(){
    if(this.isMobile){
      this.sidebarToggle(false);
    }
  }

  async notification(text, icon, title=null){
    //icons [success, error, warning, info, question]
    this.mobileSideBarHide();
    await Swal.fire({
      icon: icon,
      title: title,
      html: text
    })
  }

  dateFormatter(string, format="YYYY-MM-DD HH:mm"){
    return moment(string).format(format);
  }

  async confirmation(text, title=null, passVariable=true){
    this.mobileSideBarHide();
    return await Swal.fire({
      title: title?title:'Итгэлтэй байна уу?',
      html: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Тийм',
      cancelButtonText: 'Үгүй',
      preConfirm: () => {
        return passVariable;
      }
    });
  }

  extractRegister(register){
    let year = register.substring(2, 4);
    if(year > 49){
      year = "19"+year;
    } else {
      year = "20"+year;
    }
    let month = register.substring(4, 6);
    if(year >= 1999 && month > 20){
      month = month - 20;
    }
    let day = register.substring(6, 8);
    let gender = register.substring(8, 9);
    if(gender%2==0){
      gender = "female";
    } else {
      gender="male";
    }
    year = Number(year);
    month = Number(month);
    day = Number(day);
    if (month < 10) {
      month = "0"+month;
    }
    if(day < 10){
      day = "0"+day;
    }
    let birthDay = moment(year+"-"+month+"-"+day);
    if (birthDay.isValid()){
      let returnData = {birthday: birthDay.format("YYYY-MM-DD"), gender: gender};
      return returnData;
    } else {
      return null;
    }
  }

  phoneNumberFormatter(number){
    if(number.startsWith("976")){
      return number.substring(0,3)+"-"+number.substring(3, number.length);
    } else {
      return number;
    }
  }

  imageValidate(file){
    return new Promise((resolve)=>{
      let img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = function() {
        if(img.width) {
          const canvas = document.createElement('canvas');
          const quality = 0.7;
          if(img.width > img.height){
            canvas.width = img.width>2048?2048:img.width;
            if(img.width < 600){
              resolve({success:false, msg:"Зургийн хэмжээ жижиг байна, илүү чанартай зураг илгээнэ үү"});
            }
          } else {
            if(img.height < 600){
              resolve({success:false, msg:"Зургийн хэмжээ жижиг байна, илүү чанартай зураг илгээнэ үү"});
            }
            canvas.width = img.width>1024?1024:img.width;
          }
          canvas.height = canvas.width * (img.height / img.width);
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function(blob){
            let size = Math.ceil(blob.size/1024);
            if (size > 2048) {
              resolve({success:false, msg:"Файлын хэмжээ хэт том байна"});
            } else {
              resolve({success:true, image:canvas.toDataURL('image/jpeg', quality)})
            }
          }, 'image/jpeg', quality);
        }
      }
      img.onerror = function(){
        resolve({success:false, msg:"Шаардлага хангахгүй файл"});
      }
    });
  }

  readFileAsBase64(file){
    return new Promise((resolve)=>{
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
    });
  }

  smallNotification(text, status){
    UIkit.notification({message: text, pos:"bottom-right", status:status});
  }

  getAvatarPath(avatar) {
    return "./assets/img/avatars/"+environment.avatarPath+"/"+avatar+".png";
  }

  encrypt(data:string){
    return Base64.encode(data);
  }

  decrypt(data:string){
    return Base64.decode(data);
  }

  moneyformat(str){
    return numeral(str).format("0,0");
  }

  random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + 1 + ')';
  }
}