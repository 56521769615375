<div uk-sticky class="uk-navbar-container-profile tm-navbar-container uk-active">
    <div class="uk-container uk-container-expand">
        <nav uk-navbar>
            <div class="uk-navbar-left">
                <a (click)="main.sideBartoggler()" class="uk-navbar-toggle" uk-navbar-toggle-icon ></a>
                <a [routerLink]="['/dashboard']" class="uk-navbar-item uk-logo uk-visible@s">
                    <b>QUIZMO</b>
                </a>
            </div>
            <div class="uk-navbar-right uk-light">
                <ul class="uk-navbar-nav">
                    <li class="uk-active">
                        <a (click)="logout()"><span uk-icon="sign-out"></span> Гарах</a>
                        <!-- <a>actions&nbsp;<span class="ion-ios-arrow-down"></span></a>
                        <div uk-dropdown="pos: bottom-right; mode: click; offset: -20;" class="drowdown-menu">
                           <ul class="uk-nav uk-navbar-dropdown-nav">
                               <li><a (click)="logout()"><span uk-icon="sign-out"></span> Гарах</a></li>
                           </ul>
                        </div> -->
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>