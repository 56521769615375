<div class="home_container center">
    <div class="box">
        <img class="logo" src="./assets/img/logo.png">
        <div class="slogan">cross platform entertainment bot</div>
        <div class="platformsCont">
            <div class="platformsLogo" *ngFor="let provider of providers">
                <img class="providerLogo" src="{{provider.logo}}">
                <div style="font-weight: bold;">{{provider.name}}</div>
                <div style="font-size: 11px;"><a href="{{provider.url}}">{{provider.urlText}}</a></div>
            </div>
        </div>
        <!-- <a *ngIf="!fbAccessToken" class="uk-button uk-button-small" (click)="login()">
            <img class="chatLogo" style="margin-left:-22px" src="./assets/img/fb_logo.png">
            <span style="margin-left:20px">Facebook Login</span>
        </a>
        <a *ngIf="!fbAccessToken" class="uk-button uk-button-small" (click)="quest()">
            <img class="chatLogo" style="margin-left:-5px" src="./assets/img/quest_logo100x100.png">
            <span style="margin-left:20px">Quest гэж юу вэ?</span>
        </a>
        <a *ngIf="!fbAccessToken" class="uk-button uk-button-small" (click)="about()">
            <img class="chatLogo" style="margin-left:-6px" src="./assets/img/question.png">
            <span>quizmo гэж юу вэ?</span>
        </a> -->
    </div>
</div>
<div class="footer">
    <span><a (click)="contact()">Холбоо барих</a> | <a href="https://www.facebook.com/quizmo.mn">Facebook</a> | <a href="http://instagram.com/quizmo.mn">Instagram</a></span>
    <br>
    <span>© 2021 Quizmo.mn</span>
</div>

<div id="contact-modal" uk-modal>
    <div class="uk-modal-dialog">

        <button class="uk-modal-close-default" type="button" uk-close></button>

        <div class="uk-modal-header">
            <h2 class="uk-modal-title">Холбоо барих</h2>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
            <span uk-icon="mail"></span> <a href="mailto:quizmo.mn@gmail.com"> quizmo.mn@gmail.com</a>
            <br>
            <!-- <span uk-icon="facebook"></span> <a href="https://www.facebook.com/quizmo.mn"> www.facebook.com/quizmo.mn</a>
            <br>
            <span uk-icon="instagram"></span> <a href="https://instagram.com/quizmo.mn"> https://instagram.com/quizmo.mn</a>
            <br> -->
            <p>
                Чат үйлчилгээтэй холбоотой асуух тодруулах зүйлс болон шийдвэрлүүлэх асуудлуудаа дээрх мэйл хаягаар илгээгээрэй!
            </p>
        </div>

        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Хаах</button>
        </div>

    </div>
</div>

<div id="quest-modal" uk-modal>
    <div class="uk-modal-dialog">

        <button class="uk-modal-close-default" type="button" uk-close></button>

        <div class="uk-modal-header">
            <h2 class="uk-modal-title">Quest Coin гэж юу вэ?</h2>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
            <p>
                Quest Coin нь дэлхийн хамгийн хурдан блокчэйн сүлжээ болох Solana сүлжээнд ажилладаг крипто токен бөгөөд Quest экосистемийн бүтээгдэхүүн үйлчилгээнүүдэд ашиглагддаг урамшууллын оноо юм. Quest Coin -г ашиглан Quest экосистемд бараа бүтээгдэхүүний үйлчилгээ авахаас гадна гишүүнчлэл, урамшууллын оноо байдлаар ашиглах боломжтой байдаг. Quizmo нь Quest экесистемд нэгдэн орсон учраас өөрийн үйлчилгээнд Quest Coin-г ашиглахаар болж байгаа билээ. Та өөрийн профайл хэсэгт орж өөрийн Quest дансны дугаараа оруулаарай.
            </p>
        </div>

        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button" (click)="howToQuest()">Quest данстай болох</button>
        </div>

    </div>
</div>