import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';
declare var $;
import Swal from "sweetalert2";
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public profile;
  public alphabet = ["А","Б","В","Г","Д","Е","Ё","Ж","З","И","Й","К","Л","М","Н","О","Ө","П","Р","С","Т","У","Ү","Ф","Х","Ц","Ч","Ш","Щ","Ъ","Ы","Ь","Э","Ю","Я"];
  public configs;
  constructor(
    public main:MainService,
    private user:UserService,
    private http:HttpService,
    private errorHandler:ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.profile = this.user.getUserData("profile");
  }

  ngAfterViewInit() {
    this.configs = this.main.getConfigs();
    this.main.sidebarInitSmallDisplay();
  }

  async setUsername() {
    if (!this.configs){
      this.user.logout();
    }
    if(!this.profile.phoneNumber_verified || !this.profile.registerNumber){
      await this.main.notification("Та эхлээд хувийн мэдээлэл хэсэгт гар утасны дугаар болон регистрийн дугааруудаа оруулна уу", "warning");
      return;
    }
    try{
      let regex = /^[a-z0-9]{1,15}$/i;
      let confirm = await Swal.fire({
        title: 'Username',
        html:
        'Username нь зөвхөн латин үсэг болон тооны хольмог байж болно, дээд тал нь 15 тэмдэгтээс хэтрэх ёсгүй! хоосон зай болон бусад тусгай тэмдэгт хориглоно',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Хадгалах',
        cancelButtonText: 'Цуцлах',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      });
      if(confirm.value){
        let isIllegal = false;
        let illegalWords = ["admin", "админ", "quizmo", "administrator"];
        for(let word of illegalWords){
            if(confirm.value.toLowerCase().includes(word)){
                isIllegal = true;
                break;
            }
        }
        if (regex.test(confirm.value) && !isIllegal){
          let checkAvail = await this.http.post("profile/checkUsername", {username:confirm.value});
          if (checkAvail.success) {
            let reConfirm = await this.main.confirmation("Та <b>"+confirm.value+"</b> гэсэн нэр сонгохдоо итгэлтэй байна уу? Username анх удаа оруулж байгаа бол танаас <b>"+this.configs.prices.newUsername+"</b> qoin хасагдах болно, дахин өөрчилж байгаа бол <b>"+this.configs.prices.reUserName+"</b> qoin хасагдах болно");
            if (reConfirm.value){
              let update = await this.http.post("profile/update", {username:confirm.value});
              if(update.success){
                if(this.profile.username){
                  await this.main.notification("Амжилттай солигдлоо", "success");
                } else {
                  await this.main.notification("Та чаатын профайлаа амжилттай үүсгэлээ. Та манай Facebook Page рүү мессеж бичиж чаатлаарай", "success");
                }
                this.profile = update.data;
                this.user.saveUserData(update.data);
                window.location.reload();
              } else {
                await this.main.notification(update.msg, "warning");
                this.setUsername();
              }
            }
          } else {
            await this.main.notification("Уг Username хэрэглэгдэж байна та өөр нэр сонгоно уу", "warning");
            this.setUsername();
          }
        } else {
          await this.main.notification("Сонгосон username шаардлага хангахгүй байна", "warning");
          this.setUsername();
        }
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }

  async setPhoneNumber() {
    if (!this.configs){
      this.user.logout();
    }
    if(this.profile.phoneNumber_verified){
      let delConfirm = await Swal.fire({
        title: 'Баталгаажсан дугаар',
        html:
        'Та утасны дугаараа бүртгүүлж баталгаажуулсан байна. Та хүсвэл энэ дугаарыг устгаж профайлаасаа салгаж болно',
        showCancelButton: true,
        confirmButtonText: 'Устгах',
        cancelButtonText: 'Хаах',
        confirmButtonColor: '#e0372b',
        cancelButtonColor: '#2b95e0',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      });
      if(delConfirm.value){
        let reconfirm = await this.main.confirmation("Баталгаажуулсан дугаараа устгахдаа итгэлтэй байна уу? Та шинэ дугаар оруулахын тулд "+this.configs.prices.rePhoneNumber+" Qoin хэрэгтэй болохыг анхаарна уу");
        if(reconfirm.value){
          try {
            let delPhone = await this.http.get("profile/delPhone");
            if(delPhone.success){
              await this.main.notification("Амжилттай устгалаа", "success");
              window.location.reload();
            } else {
              this.main.notification(delPhone.msg, "warning");
            }
          } catch(ex){
            this.errorHandler.handle(ex);
          }
        }
      }
    } else {
      try{
        let regex = /^(7|8|9)[0-9]{7}$/i;
        let confirm = await Swal.fire({
          title: 'Утасны дугаар',
          html:
          'Гар утасны дугаараа SMS-р баталгаажуулах шаардлагатай тул та өөрийн дугаараа үнэн зөв оруулна уу',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Хадгалах',
          cancelButtonText: 'Цуцлах',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        });
        if(confirm.value){
          if (regex.test(confirm.value)){
            let number = "976"+confirm.value;
            if (number == this.profile.phoneNumber){
              await this.main.notification("Таны бүртгүүлсэн утасны дугаар байна", "warning");
              return;
            }
            let checkAvail = await this.http.post("profile/checkPhoneAvail", {phone:number});
            if (checkAvail.success) {
              let reConfirm = await this.main.confirmation("Та <b>"+confirm.value+"</b> дугаарыг оруулахдаа итгэлтэй байна уу? Утасны дугаар анх удаа оруулж баталгаажуулах бол танаас <b>"+this.configs.prices.newPhonenumber+"</b> qoin хасагдах болно, Өмнө нь дугаар оруулан баталгаажуулж байсан бол танаас <b>"+this.configs.prices.rePhoneNumber+"</b> qoin хасагдах болно");
              if (reConfirm.value) {
                let update = await this.http.post("profile/update", {phone:number});
                if(update.success){
                  this.profile = update.data;
                  this.user.saveUserData(update.data);
                  await this.main.notification("Амжилттай хадгалагдлаа", "success");
                } else {
                  await this.main.notification(update.msg, "warning");
                  this.setPhoneNumber();
                }
              }
            } else {
              await this.main.notification("Уг утасны дугаар ашиглагдаж байна та өөр дугаар оруулна уу", "warning");
              this.setPhoneNumber();
            }
          } else {
            await this.main.notification("Утасны дугаар шаардлага хангахгүй байна", "warning");
            this.setPhoneNumber();
          }
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }

  async setRegisterNumber() {
    if (!this.configs){
      this.user.logout();
    }
    if(this.profile.passportVerified) {
      await this.main.notification("Та бичиг баримтын баталгаажуулалт хийсэн байна, дахин солих боломжгүй. Солих шаардлагатай гэж үзэж байгаа бол бидэнд и-мэйл бичиж эсвэл инстаграмаар холбогдож лавлана уу!", "warning");
      return;
    }
    if(this.profile.passportUploaded) {
      await this.main.notification("Таны илгээсэн бичиг баримтыг шалгаж байна, ажлын 2 хоногийн дотор баталгаажих болно", "warning");
      return;
    }
    if (this.profile.registerNumber || (this.profile.passportRequired && !this.profile.passportUploaded)){
      this.uploadPassport();
      return;
    }
    let options="";
    this.alphabet.forEach(letter=>{
      options = options+"<option value="+letter+">"+letter+"</option>"
    });
    try{
      let regex = /^[0-9]{8}$/i;
      let confirm = await Swal.fire({
        title: 'Регистрийн дугаар',
        html:
        '<span style="color:orange">Та өөрийн регистрийн дугаарыг үнэн зөв оруулах шаардлагатай, буруу оруулсан тохиолдолд та өөрөө сольж чадахгүй бидэнд иргэний үнэмлэхээ илгээж солиулах болохыг анхаарна уу!</span><br><br>'+
        '<select id="register-input1" class="uk-select uk-width-1-2"><option disabled selected>Үсэг 1</option>'+options+'</select>'+
        '<select id="register-input2" class="uk-select uk-width-1-2"><option disabled selected>Үсэг 2</option>'+options+'</select>'+
        '<input id="register-input3" class="swal2-input">',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Хадгалах',
        cancelButtonText: 'Цуцлах',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
          return [
            $('#register-input1').val(),
            $('#register-input2').val(),
            $('#register-input3').val()
          ]
        }
      });
      if(confirm.value){
        if (regex.test(confirm.value[2])){
          let regNumber = confirm.value[0]+confirm.value[1]+confirm.value[2];
          let extracted = this.main.extractRegister(regNumber);
          if(extracted){
            let checkAvail = await this.http.post("profile/checkRegister", {register:regNumber});
            if (checkAvail.success){
              let reConfirm = await this.main.confirmation("Таны регистрийн дугаар <b>"+regNumber+"</b><br>Таны төрсөн өдөр <b>"+extracted.birthday+"</b><br>Таны хүйс <b>"+(extracted.gender=="male"?"Эр":"Эм")+"</b><br><br>Дээрх мэдээллүүд үнэн зөв бол цааш үргэлжлүүлнэ үү. Бүртгэлийн төлбөрт танаас <b>"+this.configs.prices.newRegisterNumber+"</b> qoin хасагдах болно");
              if (reConfirm.value){
                let update = await this.http.post("profile/update", {register:regNumber});
                if(update.success){
                  this.profile = update.data;
                  this.user.saveUserData(update.data);
                  await this.main.notification("Амжилттай хадгалагдлаа", "success");
                  window.location.reload();
                } else {
                  await this.main.notification(update.msg, "warning");
                  this.setRegisterNumber();
                }
              } else {
                let reConfirm = await this.main.confirmation("Та өөрийн бичиг баримтыг бидэнд илгээж баталгаажуулах уу?");
                if (reConfirm.value){
                  this.uploadPassport();
                }
              }
            } else {
              await this.main.notification("Уг регистрийн дугаар бүртгэгдсэн байна, та өөрийн иргэний үнэмлэх эсвэл төрсөний гэрчилгээг илгээж баталгаажуулна уу", "warning");
              this.uploadPassport();
            }
          } else {
            await this.main.notification("Регистрийн дугаар шаардлага хангахгүй байна", "warning");
            let reConfirm = await this.main.confirmation("Та өөрийн бичиг баримтыг бидэнд илгээж баталгаажуулах уу?");
            if (reConfirm.value){
              this.uploadPassport();
            }
          }
        } else {
          await this.main.notification("Регистрийн дугаар шаардлага хангахгүй байна", "warning");
          let reConfirm = await this.main.confirmation("Та өөрийн бичиг баримтыг бидэнд илгээж баталгаажуулах уу?");
          if (reConfirm.value){
            this.uploadPassport();
          }
        }
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }

  async uploadPassport(){
    if (!this.configs){
      this.user.logout();
    }
    try{
      let confirm = await Swal.fire({
        title: 'Бичиг баримт',
        html:
        '<span>иргэний үнэмлэх эсвэл төрсөний гэрчилгээний хуулбарыг илгээнэ үү</span><br><br>'+
        '<div uk-form-custom="target: true"><input type="file" accept="image/*" id="fileupload"><input class="uk-input uk-form-width-large" type="text" placeholder="Select file" disabled><a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: folder"></a></div>'
        ,
        //'<div uk-form-custom><input accept="image/*" id="fileupload" type="file"><button class="uk-button uk-button-default" type="button" tabindex="-1">Select</button></div>',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Илгээх',
        cancelButtonText: 'Цуцлах',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          let uploader = $("#fileupload");
          if (uploader.prop('files') && uploader.prop('files')[0]) {
            let imageValidate:any = await this.main.imageValidate(uploader.prop('files')[0]);
            if (imageValidate.success){
              try{
                let upload = await this.http.post("profile/uploadPassport", {data:imageValidate.image});
                if(upload.success) {
                  this.profile = upload.data;
                  this.user.saveUserData(upload.data);
                  return {success:true};
                } else {
                  return {success:false, msg: upload.msg};
                }
              } catch(ex){
                this.errorHandler.handle(ex);
                return {success:false};
              }
            } else {
              return imageValidate;
            }
          }
        }
      });
      if(confirm.value){
        if(confirm.value.success){
          await this.main.notification("Амжилттай илгээлээ", "success");
          window.location.reload();
        } else {
          if(confirm.value.msg){
            await this.main.notification(confirm.value.msg, "warning");
          }
        }
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }

  async checkPhoneVerification(){
    if (!this.configs){
      this.user.logout();
    }
    try{
      let status = await this.http.get("profile/checkPhoneVerification");
      if (status.success){
        await this.main.notification(status.msg, "success");
      } else {
        await this.main.notification(status.msg, "warning");
      }
      this.profile = status.data;
      this.user.saveUserData(status.data);
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }

  async interests(){
    if (!this.configs){
      this.user.logout();
    }
    try{
      let selectedInterests = [];
      if(this.profile.interests){
        this.profile.interests.forEach(el=>{
          selectedInterests.push(el);
        })
      }
      $(document).ready(function() {
        $(".interest_checkbox").click(function(){
          let clicked = $(this).attr("value");
          const index = selectedInterests.indexOf(clicked);
          if (index > -1) {
            selectedInterests.splice(index, 1);
          } else {
            selectedInterests.push(clicked);
          }
        })
      });
      if (this.main.configs.interests){
        let interests = "";
        this.main.configs.interests.forEach(el=>{
          let options = '<label><input class="uk-checkbox interest_checkbox" value="'+el+'" type="checkbox"> '+el+'</label>';
          if(selectedInterests.length > 0){
            if (selectedInterests.find(srch=>{return srch == el})){
              options = '<label><input class="uk-checkbox interest_checkbox" checked value="'+el+'" type="checkbox"> '+el+'</label>';
            }
          }
          interests = interests + options;
        });
        let confirm = await Swal.fire({
          title: 'Сонирхолоо тодорхойлно уу',
          html:
          '<span>доорх сэдэвүүдээс 3-г сонгоно уу</span><br><br>'+
          '<div id="interestContainer" class="uk-margin uk-grid-small uk-child-width-auto uk-grid">'+interests+'</div>',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Хадгалах',
          cancelButtonText: 'Цуцлах',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        });
        if(confirm.value){
          if (selectedInterests.length != 3){
            await this.main.notification("сэдэвүүдээс заавал гуравыг сонгоно уу", "warning");
            this.interests();
          } else {
            let update = await this.http.post("profile/update", {interests:selectedInterests});
            if(update.success){
              this.profile = update.data;
              this.user.saveUserData(update.data);
              await this.main.notification("Амжилттай хадгалагдлаа", "success");
            } else {
              await this.main.notification(update.msg, "warning");
              this.setRegisterNumber();
            }
          }
        }
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }

  async chatConfig(){
    if (!this.configs){
      this.user.logout();
    }
    try {
      if(!this.profile.registerNumber || !this.profile.phoneNumber_verified || !this.profile.gender || !this.profile.birthDate || !this.profile.username){
        await this.main.notification("Та эхлээд 'Чат профайл' болон 'Хувийн мэдээлэл' хэсгийн шаардлагатай мэдээллүүдээ оруулж баталгаажуулна уу!", "warning");
        return;
      }
      let genderOptionArray = [
        {value:"male", name:"Эрэгтэй"},
        {value:"female", name:"Эмэгтэй"},
        {value:"all_gender", name:"Хүйс хамаагүй"}
      ]
      let scoreOptionArray = [
        {value:"normal_score", name:"0 болон түүнээс дээш оноотой"},
        {value:"all_score", name:"Оноо хамаагүй"}
      ]
      let interestOptionArray = [
        {value:"same_interest", name:"Ижил сонирхолтой"},
        {value:"all_interest", name:"Сонирхол хамаагүй"}
      ]
      //gender init
      let genderOptions = '<option disabled selected>Хүйс</option>';
      genderOptionArray.forEach(option=>{
        if(this.profile.chatConfig_gender){
          if (this.profile.chatConfig_gender == option.value){
            genderOptions = genderOptions + '<option selected value="'+option.value+'">'+option.name+'</option>';
          } else {
            genderOptions = genderOptions + '<option value="'+option.value+'">'+option.name+'</option>';
          }
        } else {
          genderOptions = genderOptions + '<option value="'+option.value+'">'+option.name+'</option>';
        }
      });
      //score init
      let scoreOptions = '<option disabled selected>Оноо</option>';
      scoreOptionArray.forEach(option=>{
        if(this.profile.chatConfig_score){
          if (this.profile.chatConfig_score == option.value){
            scoreOptions = scoreOptions + '<option selected value="'+option.value+'">'+option.name+'</option>';
          } else {
            scoreOptions = scoreOptions + '<option value="'+option.value+'">'+option.name+'</option>';
          }
        } else {
          scoreOptions = scoreOptions + '<option value="'+option.value+'">'+option.name+'</option>';
        }
      });
      //interest init
      let interestOptions = '<option disabled selected>Сонирхол</option>';
      interestOptionArray.forEach(option=>{
        if(this.profile.chatConfig_interest){
          if (this.profile.chatConfig_interest == option.value){
            interestOptions = interestOptions + '<option selected value="'+option.value+'">'+option.name+'</option>';
          } else {
            interestOptions = interestOptions + '<option value="'+option.value+'">'+option.name+'</option>';
          }
        } else {
          if(!this.profile.interests || this.profile.interests.length < 1){
            if(option.value == "all_interest"){
              interestOptions = interestOptions + '<option value="'+option.value+'">'+option.name+'</option>';  
            }
          } else {
            interestOptions = interestOptions + '<option value="'+option.value+'">'+option.name+'</option>';
          }
        }
      });
  
      let confirm = await Swal.fire({
        title: 'Чатын тохиргоо',
        html:
        '<span>Чатанд санамсаргүй таарах хүнээ тохируулна уу</span><br><br>'+
        '<select id="gender-input" class="uk-select">'+genderOptions+'</select><br><br>'+
        '<select id="score-input" class="uk-select">'+scoreOptions+'</select><br><br>'+
        '<select id="interest-input" class="uk-select">'+interestOptions+'</select>',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Хадгалах',
        cancelButtonText: 'Цуцлах',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
          return [
            $('#gender-input').val(),
            $('#score-input').val(),
            $('#interest-input').val()
          ]
        }
      });
      if(confirm.value){
        let gender = confirm.value[0];
        let score = confirm.value[1];
        let interest = confirm.value[2];
        if(!gender || !score || !interest){
          await this.main.notification("Тохиргооны сонголтуудаа хийнэ үү", "warning");
          this.chatConfig();
        } else {
          let reConfirm = await this.main.confirmation("Чатын тохиргоо хийсэн төлбөрт танаас "+this.configs.prices.chatConfig+" qoin хасагдах болно");
          if (reConfirm.value){
            let update = await this.http.post("profile/update", {
              chatConfig_gender:gender,
              chatConfig_score:score,
              chatConfig_interest:interest
            });
            if(update.success){
              this.profile = update.data;
              this.user.saveUserData(update.data);
              await this.main.notification("Амжилттай хадгалагдлаа", "success");
            } else {
              await this.main.notification(update.msg, "warning");
              this.setRegisterNumber();
            } 
          }
        }
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }

}
