import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MainService } from 'src/app/services/main.service';
import { environment } from '../../../environments/environment';
declare var UIkit;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email:string;
  public password:string;
  constructor(
    private http:HttpService,
    private main:MainService
  ) { }

  ngOnInit(): void {
  }

  async login(){
    if(this.email && this.password){
      let login = await this.http.post("login", {email:this.email, password:this.password}, true);
      if(login.success){
        window.location.href = "/admin-dashboard"
      } else {
        await this.main.notification(login.msg, "error" ,"Амжилтгүй");
        window.location.href = "/login"
      }
    }
  }
}
