import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/fragments/header/header.component';
import { SidebarComponent } from './components/fragments/sidebar/sidebar.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ChatHistoryComponent } from './components/chat-history/chat-history.component';
import { LikedComponent } from './components/liked/liked.component';
import { BlockedComponent } from './components/blocked/blocked.component';
import { QoinComponent } from './components/qoin/qoin.component';
import { ItemsComponent } from './components/items/items.component';
import { TermsComponent } from './components/terms/terms.component';
import { SearchComponent } from './components/search/search.component';
import { AdminIncomesComponent } from './components/admin-incomes/admin-incomes.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminDocumentsComponent } from './components/admin-documents/admin-documents.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AdminProfilesComponent } from './components/admin-profiles/admin-profiles.component';
import { AdminProfileComponent } from './components/admin-profile/admin-profile.component';
import { AdminReportsComponent } from './components/admin-reports/admin-reports.component';
import { GameHangmanComponent } from './components/game-hangman/game-hangman.component';
import { AdminDataclassifyComponent } from './components/admin-dataclassify/admin-dataclassify.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    AgreementComponent,
    PrivacyPolicyComponent,
    ChatHistoryComponent,
    LikedComponent,
    BlockedComponent,
    QoinComponent,
    ItemsComponent,
    TermsComponent,
    SearchComponent,
    AdminIncomesComponent,
    AdminDashboardComponent,
    AdminDocumentsComponent,
    DashboardComponent,
    AdminProfilesComponent,
    AdminProfileComponent,
    AdminReportsComponent,
    GameHangmanComponent,
    AdminDataclassifyComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
