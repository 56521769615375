<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <div class="uk-card uk-card-default uk-card-body uk-width-1-1">
                <h3 class="uk-card-title">Quest гэж юу вэ?</h3>
                <p style="text-align: justify">
                    Quest Coin нь дэлхийн хамгийн хурдан блокчэйн сүлжээ болох Solana сүлжээнд ажилладаг крипто токен бөгөөд Quest экосистемийн бүтээгдэхүүн үйлчилгээнүүдэд ашиглагддаг урамшууллын оноо юм. Quest Coin -г ашиглан Quest экосистемд бараа бүтээгдэхүүний үйлчилгээ авахаас гадна гишүүнчлэл, урамшууллын оноо байдлаар ашиглах боломжтой байдаг. Quizmo нь Quest экесистемд нэгдэн орсон учраас өөрийн үйлчилгээнд Quest Coin-г ашиглахаар болж байгаа билээ. Та өөрийн профайл хэсэгт орж өөрийн Quest дансны дугаараа оруулаарай.
                </p>
                Та Quest Coin -н талаар илүү дэлгэрэнгүй мэдээлэл авах бол <a href="https://questcoin.org" target="_blank">https://questcoin.org</a> хаягаар мэдээлэл аваарай
            </div>
            <!-- <ul class="uk-tab" data-uk-tab="{connect:'#qoinTabs'}">
                <li class="uk-active"><a href="">Qoin авах заавар</a></li>
                <li><a (click)="getActivities()">Орлого</a></li>
                <li><a (click)="getActivities()">Зарлага</a></li>
            </ul> -->
            <!-- <ul id="qoinTabs" class="uk-switcher">
                <li>
                    <div *ngIf="profile._id != '5edf24c0c70f817e034bc691'">
                        <div *ngIf="bankstatus && bankstatus.running" class="uk-card uk-card-default uk-card-body uk-width-1-1">
                            <h3 class="uk-card-title">Qoin худалдан авах заавар</h3>
                            <p>Та дараах данснуудын аль нэгэнд худалдан авах Qoin-доо тааруулан мөнгөө шилжүүлж Qoin оо аваарай. Нэг Qoin 
                                <b style="color:rgb(33, 179, 89)">{{coinPrice}}</b> төгрөгийн үнэтэй. Шөнө 00 цагаас хойш шилжүүлсэн мөнгө өглөө 06 цагаас хойш Qoin болж орохыг анхаарна уу!</p>
                            <p style="text-align: justify">Та мөнгөө шилжүүлэхдээ гүйлгээний утган дээр <b style="color:rgb(201, 17, 63)">{{profile.id}}</b> гэсэн дугаарыг заавал бичиж шилжүүлээрэй. Уг код нь таныг таних тэмдэг бөгөөд бичихгүй бол таны дансанд Qoin орохгүй бөгөөд шилжүүлсэн мөнгийг буцаах боломжгүй болох юм.</p>
                        </div>
                        <div *ngIf="!bankstatus || (bankstatus && !bankstatus.running)" class="uk-card uk-card-default uk-card-body uk-width-1-1">
                            <h3 class="uk-card-title">Мэдэгдэл</h3>
                            <p *ngIf="!bankstatus.message">Тодорхой бус хугацаагаар банкны данс ашиглан Qoin худалдаа хийгдэхгүй болохыг мэдэгдэж байна. Систем хэвийн болох үед та бүхэнд Фэйсбүүк хуудасаар дамжуулан мэдэгдэх болно</p>
                            <p *ngIf="bankstatus.message">{{bankstatus.message}}</p>
                            <p>Qoin -н олборлолт хэвийн хийгдэж байгаа болно.</p>
                        </div>
                        <div *ngIf="accs.length && (bankstatus && bankstatus.running)" class="uk-card uk-card-default uk-card-body uk-width-1-2@m uk-margin-top">
                            <form *ngFor="let acc of accs" class="uk-form-stacked uk-width-1-1@s">
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="username">Банк</label>
                                    <div class="uk-inline uk-width-1-1@s">
                                        <input class="uk-input" name="bankname" id="username" type="text" disabled value="{{acc.bank}}">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="messengerid">Дансны дугаар</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="acc" id="acc" type="text" disabled value="{{acc.acc}}">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="messengerid">Хүлээн авагчийн нэр</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="accname" id="accname" type="text" disabled value="{{acc.name}}">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="messengerid">Гүйлгээний утга</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" name="accname" id="accname" type="text" disabled value="{{profile.id}}">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div *ngIf="profile._id == '5edf24c0c70f817e034bc691'">
                        <div class="uk-card uk-card-default uk-card-body uk-width-1-1">
                            <h3 class="uk-card-title">Qoin худалдаа зогссон байна</h3>
                            <p>Та одоогоор qoin худалдан авах боломжгүй, qoin худалдаа түр зогссон байна</p>
                        </div>
                    </div>
                </li>
                <li>
                    <table *ngIf="activities && activities.incomes.length > 0" class="uk-table uk-table-striped">
                        <thead>
                            <tr>
                                <th>Дүн</th>
                                <th>Хаанаас</th>
                                <th class="uk-visible@s">Гүйлгээний утга</th>
                                <th class="uk-visible@s">Огноо</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let income of activities.incomes; let i=index">
                                <td>{{income.amount}} qoin</td>
                                <td>{{income.from}}</td>
                                <td class="uk-visible@s">{{income.description}}</td>
                                <td class="uk-visible@s">{{main.dateFormatter(income.date, "YYYY-MM-DD HH:mm")}}</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    <table *ngIf="activities && activities.outcomes.length > 0" class="uk-table uk-table-striped">
                        <thead>
                            <tr>
                                <th>Дүн</th>
                                <th>Тайлбар</th>
                                <th class="uk-visible@s">Хэнд</th>
                                <th class="uk-visible@s">Огноо</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let outcome of activities.outcomes; let i=index">
                                <td>{{outcome.amount}} qoin</td>
                                <td>{{outcome.description}}</td>
                                <td class="uk-visible@s">{{outcome.to?outcome.to:'quizmo'}}</td>
                                <td class="uk-visible@s">{{main.dateFormatter(outcome.date, "YYYY-MM-DD HH:mm")}}</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul> -->
        </div>
    </div>
</div>