import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-qoin',
  templateUrl: './qoin.component.html',
  styleUrls: ['./qoin.component.scss']
})
export class QoinComponent implements OnInit {
  accs = [];
  activities;
  bankstatus = null;
  profile;
  coinPrice = 100;
  constructor(
    public main: MainService,
    private user: UserService,
    private http: HttpService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.profile = this.user.getUserData();
    this.getBankStatus();
    this.accs = this.main.configs.banks;
    this.coinPrice = this.main.configs.qoinPrice;
  }

  ngAfterViewInit() {
    this.main.sidebarInitSmallDisplay();
  }

  async getActivities(){
    if (!this.activities){
      try {
        let activities = await this.http.get("profile/getActivities");
        if(activities.success){
          this.activities = activities.data;
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }
  async getBankStatus(){
    if (!this.activities){
      try {
        let status = await this.http.get("profile/getBankStatus");
        if(status.success){
          this.bankstatus = status.data;
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }
}
