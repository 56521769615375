<!-- <app-header></app-header>
<app-sidebar></app-sidebar> -->
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul uk-tab>
                <li (click)="selectType('sentence')" class="uk-active"><a>Sentence</a></li>
                <li (click)="selectType('article')"><a>Article</a></li>
            </ul>
            <img class="refreshBtn" src="./assets/img/refresh.png" (click)="getDatas()"/>
            <span (click)="reportAction(selectedText?.userId)" class="banBtn" uk-icon="icon: ban; ratio: 2" [ngClass]="{'active':selectedText?.userId}"></span>
            <div class="dataClassifyContainer">
                <div *ngIf="selectedType == 'sentence'" class="textCont">
                    <label class="count">{{datas.length}}</label>
                    <div class="messageCont">
                        <label class="message" [ngClass]="selectedTextPredict">{{selectedText?selectedText.message:''}}</label>
                    </div>
                    <div class="back_next_btn">
                        <span (click)="prev()" uk-icon="icon: chevron-left; ratio: 2" [ngClass]="{'active':prevDatas.length}"></span>
                    </div>
                </div>
                <div *ngIf="selectedType == 'article'" class="textCont">
                    <label class="count">{{datas.length}}</label>
                    <div class="messageCont">
                        <div *ngFor="let msg of articleParticles">
                            <span class="message" (click)="createNewMessage(msg.message, selectedText?.userId)" [ngClass]="msg.label">{{msg.message}}</span>
                        </div>
                    </div>
                    <div class="back_next_btn">
                        <span (click)="prev()" uk-icon="icon: chevron-left; ratio: 2" [ngClass]="{'active':prevDatas.length}"></span>
                    </div>
                </div>
            </div>
            <div class="btnCont">
                <img src="./assets/img/thumbsDown.png" (click)="next(this.selectedText._id,'bad')"/>
                <img src="./assets/img/thumbsUp.png" (click)="next(this.selectedText._id,'normal')"/>
            </div>
        </div>
    </div>
</div>