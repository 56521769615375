<div id="sidebar" class="tm-sidebar-left uk-background-default">
    <div class="user" style="text-align: center;">
        <img id="avatar" width="100" class="uk-border-circle" src="{{profile.avatar?main.getAvatarPath(amILuckyStar(profile.avatar.id)):profile.gender?main.getAvatarPath(amILuckyStar(profile.gender)):'./assets/img/avatars/unknown.png'}}" />
        <div class="uk-margin-top"></div>
        <div id="name" class="uk-text-truncate">{{profile.username?profile.username:"Username"}}</div>
        <!-- <div id="email" class="uk-text-truncate"><button class="uk-button uk-button-default qoin-button"><img class="qoin" src="./assets/img/qoin.png"></button></div> -->
        <div id="email" class="uk-text-truncate">{{profile.phoneNumber?main.phoneNumberFormatter(profile.phoneNumber):"Утасны дугаар"}}</div>
        <!-- <span id="status" data-enabled="true" data-online-text="Online" data-away-text="Away" data-interval="10000" class="uk-margin-top uk-label uk-label-success"></span> -->
    </div>
    <br />
    <ul class="uk-nav uk-nav-default">
        <li *ngIf="profile.isAdmin"><a [routerLink]="['/admin-dashboard']">Dashboard</a></li>
        <li *ngIf="profile.isAdmin"><a [routerLink]="['/admin-incomes']">Bank Incomes</a></li>
        <li *ngIf="profile.isAdmin"><a [routerLink]="['/game-hangman']">Game Hangman</a></li>
        <li *ngIf="profile.isAdmin"><a [routerLink]="['/admin-profiles']">Profiles</a></li>
        <li *ngIf="profile.isAdmin"><a [routerLink]="['/admin-reports']">Reports</a></li>
        <li *ngIf="profile.isAdmin"><a [routerLink]="['/admin-dataclassify']">Data Classify</a></li>
    </ul>
</div>