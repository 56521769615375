<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul class="uk-tab" data-uk-tab="{connect:'#itemsTabs'}">
                <li class="uk-active"><a href="">Аватар</a></li>
                <li><a href="">Бусад</a></li>
            </ul>
            <ul id="itemsTabs" class="uk-switcher">
                <li>
                    <div class="uk-child-width-1-3 uk-child-width-1-6@s uk-child-width-1-10@m uk-grid-small uk-grid-match" uk-grid>
                        <div *ngFor="let avatar of avatars">
                            <div class="uk-card uk-card-body uk-padding-remove avatar-card" (click)="buy(avatar._id, avatar.price)">
                                <img class="" src="{{main.getAvatarPath(avatar.id)}}">
                                <div class="item-check empty" *ngIf="!checkIfBought(avatar._id)"></div>
                                <img id="avatar" *ngIf="checkIfBought(avatar._id)" class="uk-border-circle item-check" src="./assets/img/items-check.png">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    тун удахгүй ...
                </li>
            </ul>
        </div>
    </div>
</div>