import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboard = null;
  profile;
  constructor(
    public main: MainService,
    private user: UserService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private http: HttpService
  ) { }

  ngOnInit(): void {
    this.profile = this.user.getUserData();
  }

  ngAfterViewInit(){
    // if(!this.user.getUserData("configs") || !this.user.getUserData("token") || !this.user.getUserData("unreadmsgs")){
    //   this.router.navigate(["/login"]);
    // }
    this.getDashboard();
  }

  async getDashboard(){
    if(!this.dashboard){
      try{
        let dashb = await this.http.get("profile/dashboard");
        if (dashb.success){
          this.dashboard = dashb.data;
          if(this.dashboard.luckyStar){
            this.user.saveUserData(this.dashboard.luckyStar._id, "luckyStar");
          }
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }

  balanceRefresh(){
    window.location.reload();
  }

}
