<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul class="uk-tab" data-uk-tab="{connect:'#reportsTab'}">
                <li class="uk-active"><a href="">New Reports</a></li>
                <li><a>Report analyze</a></li>
            </ul>
            <ul id="reportsTab" class="uk-switcher">
                <li>
                    <span *ngIf="newReports.length < 1">No Reports</span>
                    <div *ngIf="newReports.length > 0" class="uk-card uk-card-default uk-card-body">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-visible@m">No</th>
                                    <th class="uk-visible@m" style="text-align: left;">Participant</th>
                                    <th class="uk-visible@m" style="text-align: left;">Date</th>
                                    <th style="text-align: left;">Resolve</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let report of newReports; let i=index">
                                <tr>
                                    <td class="uk-visible@m">{{i+1}}</td>
                                    <td class="uk-visible@m">{{report.sent}}, {{report.received}}</td>
                                    <td class="uk-visible@m">{{main.dateFormatter(report.createdAt)}}</td>
                                    <td><button (click)="resolveReport(report.room, report.sent, report.received, report._id)">resolve</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>                            
                    <button class="uk-button uk-button-primary button-border-radius" type="button" style="margin-right: 10px;" (click)="getReportsByGroup('received')">Get by Received count</button>
                    <button class="uk-button uk-button-primary button-border-radius" type="button" (click)="getReportsByGroup('sent')">Get by Sent count</button>
                    <div style="margin-top:10px" *ngIf="groupedReports.length > 0 && !filteredReports.length" class="uk-card uk-card-default uk-card-body">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-visible@m">No</th>
                                    <th class="uk-visible@m" style="text-align: left;">ID</th>
                                    <th class="uk-visible@m" style="text-align: left;">count</th>
                                    <th class="uk-visible@m" style="text-align: left;">Analyze</th>
                                    <th class="uk-visible@m" style="text-align: left;">Get</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let report of groupedReports; let i=index">
                                <tr>
                                    <td class="uk-visible@m">{{i+1}}</td>
                                    <td class="uk-visible@m">{{report._id}}</td>
                                    <td class="uk-visible@m">{{report.count}}</td>
                                    <td class="uk-visible@m">
                                        <button class="uk-button uk-button-primary button-border-radius" type="button" style="margin-right: 10px;" (click)="reportAnalyze(report.type, report._id)">Analyze</button>
                                    </td>
                                    <td class="uk-visible@m">
                                        <button class="uk-button uk-button-primary button-border-radius" type="button" style="margin-right: 10px;" (click)="getReportsByQuery(report.type, report._id)">Get</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="filteredReports.length > 0" class="uk-card uk-card-default uk-card-body">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-visible@m">No</th>
                                    <th class="uk-visible@m" style="text-align: left;">Participant</th>
                                    <th class="uk-visible@m" style="text-align: left;">Date</th>
                                    <th style="text-align: left;">Resolve</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let report of filteredReports; let i=index">
                                <tr>
                                    <td class="uk-visible@m">{{i+1}}</td>
                                    <td class="uk-visible@m">{{report.sent}}, {{report.received}}</td>
                                    <td class="uk-visible@m">{{main.dateFormatter(report.createdAt)}}</td>
                                    <td><button (click)="resolveReport(report.room, report.sent, report.received, report._id)">resolve</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>       
                </li>
            </ul>
        </div>
    </div>
</div>

<div id="modal-reportChatText" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h4 class="uk-modal-title">Report sent: {{this.participants.sent.name}}</h4>
            <button (click)="Analyze()" class="uk-button button-border-radius" style="float:left"><div uk-spinner *ngIf="loading"></div><span *ngIf="!loading" uk-icon="refresh"></span></button>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
            <ul *ngIf="chatText && chatText.length > 0" class="uk-list uk-list-divider">
                <li *ngFor="let txt of chatText" class="logText" (click)="createNewMessage(txt.text, txt.id)" [ngClass]="{'badWord':txt.badWord}">{{main.dateFormatter(txt.timestamp, 'HH:mm:ss')}} : <b [ngClass]="{'sentUser': txt.id == participants.sent.id}">{{txt.username}}: </b>{{ txt.text }}</li>
            </ul>
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button style="margin-right:5px" (click)="dropDownMenu('Sent')" class="uk-button uk-button-danger button-border-radius" type="button">{{this.participants.sent.name}} {{this.participants.sent.tempBanCount}}</button>
            <div id="actionsSent" style="border-radius: 5px; padding:10px" uk-dropdown="pos: bottom-right; mode: click; offset: 0;" class="drowdown-menu">
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.sent.id, 'fake_report')"><span>Fake report</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.sent.id, 'one_month')"><span>One Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.sent.id, 'two_months')"><span>Two Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.sent.id, 'three_months')"><span>Three Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.sent.id, 'six_months')"><span>Six Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.sent.id, 'permanent')"><span>Permanent Ban</span></div>
            </div>
            <button style="margin-right:5px" (click)="dropDownMenu('Received')" class="uk-button uk-button-danger button-border-radius" type="button">{{this.participants.received.name}} {{this.participants.received.tempBanCount}}</button>
            <div id="actionsReceived" style="border-radius: 5px; padding:10px" uk-dropdown="pos: bottom-right; mode: click; offset: 0;" class="drowdown-menu">
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.received.id, 'one_month')"><span>One Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.received.id, 'two_months')"><span>Two Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.received.id, 'three_months')"><span>Three Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.received.id, 'six_months')"><span>Six Months</span></div>
                <div class="uk-width-1-1 dropDownItem" (click)="reportAction(this.participants.received.id, 'permanent')"><span>Permanent Ban</span></div>
            </div>
            <button class="uk-button uk-button-primary uk-modal-close button-border-radius" type="button" (click)="doneResolve()">Done</button>
        </div>

    </div>
</div>