<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul class="uk-tab" data-uk-tab="{connect:'#adminProfileTabs'}">
                <li class="uk-active"><a>Info</a></li>
                <li><a>Incomes</a></li>
                <li><a>Outcomes</a></li>
                <li><a>Chat history</a></li>
                <li><a>Notification</a></li>
            </ul>
            <ul id="adminProfileTabs" class="uk-switcher">
                <li uk-grid class="uk-child-width-1-1">
                    <div>
                        <div class="uk-card uk-card-default uk-card-body">
                            <form *ngIf="userDetail" class="uk-form-stacked">
                                <div class="uk-margin" *ngIf="userDetail.banned">
                                    <label class="uk-form-label" for="isbanned">User Banned</label>
                                    <div class="uk-form-controls">
                                        <input style="background-color: red; color:white" class="uk-input" disabled [ngModel]="userDetail.banned" name="isbanned" id="isbanned" type="text">
                                    </div>
                                    <div>{{userDetail.banReason}}</div>
                                </div>
                                <div class="uk-margin" *ngIf="!userDetail.active">
                                    <label class="uk-form-label" for="isactive">User Active</label>
                                    <div class="uk-form-controls">
                                        <input style="background-color: rgb(255, 173, 173);" class="uk-input" disabled [ngModel]="userDetail.active" name="isactive" id="isactive" type="text">
                                    </div>
                                    <div>{{userDetail.disabledReason}}</div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="username">Username</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" disabled [ngModel]="userDetail.username" name="username" id="username" type="text">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="userAlias">Alias</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" disabled [ngModel]="userDetail.id" name="userAlias" id="userAlias" type="text">
                                    </div>
                                </div>
                                <div class="uk-margin" *ngIf="userDetail.phoneNumber">
                                    <label class="uk-form-label" for="phoneNumber">Phone Number</label>
                                    <div class="uk-form-controls uk-inline uk-width-1-1@s">
                                        <input class="uk-input" disabled value="{{userDetail.phoneNumber}}" name="phoneNumber" id="phoneNumber" type="text">
                                        <a style="color:orange" *ngIf="userDetail.phoneNumber && !userDetail.phoneNumber_verified"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: info"></a>
                                        <a style="color:green" *ngIf="userDetail.phoneNumber_verified"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: check"></a>
                                        <a style="color:red" *ngIf="!userDetail.phoneNumber"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: warning"></a>
                                    </div>
                                </div>
                                <div class="uk-margin" *ngIf="userDetail.registerNumber">
                                    <label class="uk-form-label" for="registerNumber">Register Number</label>
                                    <div class="uk-form-controls uk-inline uk-width-1-1 uk-width-3-4@s">
                                        <input class="uk-input" disabled value="{{userDetail.registerNumber}}" name="registerNumber" id="registerNumber" type="text">
                                        <a style="color:orange" *ngIf="userDetail.passportUploaded"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: info"></a>
                                        <a style="color:green" *ngIf="userDetail.passportVerified"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: check"></a>
                                        <a style="color:red" *ngIf="!userDetail.registerNumber"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: warning"></a>
                                    </div>
                                    <button [disabled]="loading" *ngIf="userDetail.passportVerified" (click)="watchDocument(userDetail._id)" class="uk-button uk-button-primary uk-width-1-1 uk-width-1-4@s">Watch Document</button>
                                    <button [disabled]="loading" *ngIf="!userDetail.passportVerified" (click)="documentRequired(userDetail._id)" class="uk-button uk-button-default uk-width-1-1 uk-width-1-4@s">Document required</button>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="birthDate">Birth Date</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" disabled value="{{main.dateFormatter(userDetail.birthDate, 'YYYY-MM-DD')}}" name="birthDate" id="birthDate" type="text">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="gender">Gender</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" disabled value="{{userDetail.gender}}" name="gender" id="gender" type="text">
                                    </div>
                                </div>
                                <div class="uk-margin" *ngIf="userDetail.fbProfile">
                                    <label class="uk-form-label" for="fbUserName">FB username</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" disabled value={{userDetail.fbProfile.name?userDetail.fbProfile.name:userDetail.fbProfile.id}} name="fbUserName" id="fbUserName" type="text">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="score">Score</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" disabled value="{{userDetail.score?userDetail.score:0}}" name="score" id="score" type="text">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="qoin">Qoin</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" disabled value="{{userDetail.qoins?userDetail.qoins:0}}" name="qoin" id="qoin" type="text">
                                    </div>
                                </div>
                            </form>
                            <button *ngIf="!userDetail.banned" (click)="ban(userDetail._id)" class="uk-button uk-button-danger">Ban</button>
                            <button *ngIf="userDetail.active" (click)="disable(userDetail._id)" class="uk-button uk-button-default">Disable</button>
                        </div>
                    </div>
                </li>
                <li>
                    <span *ngIf="!incomes">Loading ...</span>
                    <span *ngIf="incomes && incomes.length < 1">No incomes</span>
                    <div *ngIf="incomes && incomes.length > 0" class="uk-card uk-card-default uk-card-body">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th style="text-align: left;">Amount</th>
                                    <th style="text-align: left;">Description</th>
                                    <th style="text-align: left;">Type</th>
                                    <th style="text-align: left;">TxnDate</th>
                                    <th style="text-align: left;">CreatedDate</th>
                                    <th style="text-align: left;">Status</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let income of incomes; let i=index">
                                <tr id="{{income._id}}">
                                    <td>{{i+1}}</td>
                                    <td>{{main.moneyformat(income.amount)}}</td>
                                    <td>{{income.description}}</td>
                                    <td>{{income.incomeType}}</td>
                                    <td>{{income.txnDate}}</td>
                                    <td>{{main.dateFormatter(income.createdDate)}}</td>
                                    <td>{{income.status}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <span *ngIf="!outcomes">Loading ...</span>
                    <span *ngIf="outcomes && outcomes.length < 1">No outcomes</span>
                    <div *ngIf="outcomes && outcomes.length > 0" class="uk-card uk-card-default uk-card-body">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th style="text-align: left;">Amount</th>
                                    <th style="text-align: left;">Description</th>
                                    <th style="text-align: left;">To</th>
                                    <th style="text-align: left;">CreatedDate</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let outcome of outcomes; let i=index">
                                <tr id="{{outcome._id}}">
                                    <td>{{i+1}}</td>
                                    <td>{{outcome.amount}} Qoin</td>
                                    <td>{{outcome.description}}</td>
                                    <td>{{outcome.to}}</td>
                                    <td>{{main.dateFormatter(outcome.createdDate)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <span *ngIf="!chatHistory">Loading ...</span>
                    <span *ngIf="chatHistory && chatHistory.length < 1">No incomes</span>
                    <div *ngIf="chatHistory && chatHistory.length > 0" class="uk-card uk-card-default uk-card-body">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th style="text-align: left;">Participant</th>
                                    <th style="text-align: left;">Log Uploaded</th>
                                    <th style="text-align: left;">Date</th>
                                    <th style="text-align: left;">Log</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let chat of chatHistory; let i=index">
                                <tr id="{{chat._id}}">
                                    <td>{{i+1}}</td>
                                    <td>{{findMyPaticipantName(chat)}}</td>
                                    <td>{{chat.logUploaded}}</td>
                                    <td>{{main.dateFormatter(chat.date)}}</td>
                                    <td><button [disabled]="!chat.logUploaded" (click)="getChatLog(chat.room, chat.names, chat.date)">Log</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <div>
                        notification
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div id="modal-chatText" uk-modal>
    <div class="uk-modal-dialog">

        <button class="uk-modal-close-default" type="button" uk-close></button>

        <div class="uk-modal-header">
            <h4 class="uk-modal-title">{{partnerName}} {{main.dateFormatter(chatDate)}}</h4>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
            <ul *ngIf="chatText && chatText.length > 0" class="uk-list uk-list-divider">
                <li *ngFor="let txt of chatText">{{main.dateFormatter(txt.timestamp, 'HH:mm:ss')}} : <b>{{txt.username}}: </b>{{ txt.text }}</li>
            </ul>
        </div>

        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-primary uk-modal-close button-border-radius" type="button">Хаах</button>
        </div>

    </div>
</div>

<div id="document-modal-onProfile" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Uploaded document</h2>
        <img id="uploadedDocumentModal" name="" src="" alt="Loading ...">

        <p class="uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
        </p>
    </div>
</div>