import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpService } from 'src/app/services/http.service';

import Swal from "sweetalert2";
declare var UIkit;
@Component({
  selector: 'app-admin-dataclassify',
  templateUrl: './admin-dataclassify.component.html',
  styleUrls: ['./admin-dataclassify.component.scss']
})
export class AdminDataclassifyComponent implements OnInit {
  public datas:any[] = [];
  public prevDatas:any[] = [];
  public selectedText:any = null;
  public selectedTextPredict:string = '';
  public selectedType:string = 'sentence'
  public articleParticles:any[] = [];
  constructor(
    public main: MainService,
    private http: HttpService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.getDatas();
  }

  selectType(type){
    this.selectedType = type;
    this.getDatas();
  }

  async getDatas(){
    const datas = await this.http.post('getMlClassifyData', {type: this.selectedType}, true);
    if(datas.data.length){
      this.datas = datas.data;
      this.getNextMessage();
    }
  }

  async getNextMessage(){
    this.selectedText = this.datas[0];
    if(this.selectedType == 'sentence'){
      this.selectedTextPredict = '';
      const prediction = await this.http.post('predictMessage',{message:this.selectedText.message}, true);
      if(prediction?.data?.prediction?.label_text){
        this.selectedTextPredict = prediction?.data?.prediction?.label_text;
      }
    } else {
      const particles = this.selectedText.message.split('\n');
      this.articleParticles = [];
      for (let particle of particles){
        const prediction = await this.http.post('predictMessage',{message:particle}, true);
        if(prediction?.data?.prediction?.label_text){
          this.articleParticles.push({message: particle, label: prediction?.data?.prediction?.label_text});
        } else {
          this.articleParticles.push({message: particle, label: 'null'});
        }
      }
    }
  }

  async next(messageId:string, option:string){
    const shifted = this.datas.shift();
    this.prevDatas.push(shifted);
    if(this.datas.length){
      this.getNextMessage();
      this.updateMessage(messageId, option);
    } else {
      await this.updateMessage(messageId, option);
      await this.getDatas();
    }
  }

  async prev(){
    if(this.prevDatas.length){
      this.datas.unshift(this.prevDatas[this.prevDatas.length-1]);
      this.prevDatas.pop();
      this.selectedText = this.datas[0];
      this.getNextMessage()
    }
  }

  async updateMessage(id, option){
    await this.http.post('updateMlClassifyData',{messageId:id, category:option}, true);
  }

  async createNewMessage(message, userId){
    let confirm = await Swal.fire({
      title: "Итгэлтэй байна уу",
      html:`${message} өгүүлбэрийг бүртгэх үү?`,
      showCancelButton: true,
      confirmButtonText: 'Илгээх',
      cancelButtonText: 'Цуцлах'
    });
    if (confirm.value){
      try {
        const res = await this.http.post('createMlClassifyData',{message, userId}, true);
        if(res.success){
          await this.main.notification("Амжилттай", "success");
        } else {
          await this.main.notification("Амжилтгүй", "warning");
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }

  async reportAction(userId){
    if(userId){
      let reqData = {
        uid: userId,
        action:'two_months'
      }
      let confirm = await Swal.fire({
        title: "Итгэлтэй байна уу",
        html:"Уг хэрэглэгчийг 2 сараар бандах үйлдэл хийхдээ итгэлтэй байна уу",
        showCancelButton: true,
        confirmButtonText: 'Илгээх',
        cancelButtonText: 'Цуцлах'
      });
      if (confirm.value){
        try {
          let reportActions = await this.http.post("resolveReportAction", reqData, true);
          if(reportActions.success){
            await this.main.notification(reportActions.msg?reportActions.msg:"Үйлдэл амжилттай хийгдлээ", "success");
          } else {
            await this.main.notification(reportActions.msg, "warning");
          }
        } catch(ex){
          this.errorHandler.handle(ex);
        }
      }
    }
  }

}
