<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <h3>Таалагдсан хүмүүс</h3>
            <span *ngIf="!loading && likedList.length < 1">Танд таалагдсан хүн байхгүй байна</span>
            <table *ngIf="likedList.length > 0" class="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>Харилцагч</th>
                        <th class="uk-text-right uk-hidden@s">Үйлдэл</th>
                        <th class="uk-text-right uk-visible@s">Хувийн чат</th>
                        <th class="uk-text-right uk-visible@s">Устгах</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let liked of likedList; let i=index">
                        <td><img src="{{user.myPartners[liked.likedProfile] && user.myPartners[liked.likedProfile].avatar?main.getAvatarPath(user.myPartners[liked.likedProfile].avatar):'./assets/img/avatars/unknown.png'}}" alt="Avatar" class="avatar uk-visible@s" style="margin-right:10px">{{user.myPartners[liked.likedProfile] && user.myPartners[liked.likedProfile].username?user.myPartners[liked.likedProfile].username:'loading ...'}}</td>
                        <td class="uk-text-right uk-visible@s"><button [disabled]="loading" class="uk-button uk-button-primary button-border-radius" (click)="privateChat(liked.likedProfile)">Хувийн чат</button></td>
                        <td class="uk-text-right uk-hidden@s">
                            <button [disabled]="loading" class="uk-button uk-button-primary button-border-radius"><span class="ion-ios-arrow-down"></span></button>
                            <div uk-dropdown="pos: bottom-right; mode: click; offset: -10;" id="actionsDrowpdown" class="drowdown-menu uk-width-1-1">
                                <button class="uk-button uk-button-primary uk-width-1-2" (click)="privateChat(liked.likedProfile)">Чат</button>
                                <button class="uk-button uk-button-danger uk-width-1-2" (click)="delete(liked._id)">Устгах</button>
                             </div>
                        </td>
                        <td class="uk-text-right uk-visible@s"><button [disabled]="loading" (click)="delete(liked._id)" class="uk-button uk-button-danger button-border-radius">Устгах</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>