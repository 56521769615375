import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import Swal from "sweetalert2";
declare var UIkit;
@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss']
})
export class AdminReportsComponent implements OnInit {
  newReports = [];
  groupedReports = [];
  resolvedReports = [];
  filteredReports = [];
  loading = false;
  //resolve related
  selectedReport;
  chatText = [];
  participants = {
    sent:{id:null, name:null, tempBanCount:0},
    received:{id:null, name:null, tempBanCount:0}
  };
  constructor(
    public main: MainService,
    private errorHandler: ErrorHandlerService,
    private http: HttpService,
    private user: UserService
  ) { }

  ngOnInit(): void {
    this.getReports();
  }

  async getReports(){
    try{
      let reports = await this.http.get("getReports", true);
      if(reports.success){
        if (reports.data.length > 0){
          reports.data.forEach(element => {
            if(element.resolved){
              this.resolvedReports.push(element);
            } else {
              this.newReports.push(element);
            }
          });
        }
      } else {
        this.main.notification(reports.msg, "warning");
      }
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async getReportsByQuery(option, uid){
    let query = null;
    if(option === "sent"){
      query = {sent:uid, resolved:false}
    } else {
      query = {received:uid, resolved:false}
    }
    try{
      let reports = await this.http.post("getReportsByQuery", query, true);
      if(reports.success){
        if (reports.data.length > 0){
          this.filteredReports = reports.data;
        }
      } else {
        this.main.notification(reports.msg, "warning");
      }
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async getReportsByGroup(option){
    try{
      this.filteredReports = [];
      let reports = await this.http.post("getReportsByGroup", {option}, true);
      if(reports.success){
        this.groupedReports = reports.data;
      } else {
        this.main.notification(reports.msg, "warning");
      }
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async reportAnalyze(option, uid){
    let query = null;
    if(option === "sent"){
      query = {sent:uid}
    } else {
      query = {received:uid}
    }
    try{
      let reports = await this.http.post("reportAnalyze", {query}, true);
      if(reports.success){
        let msg = "";
        reports.data.forEach(element => {
          msg = msg+element+"</br>";
        });
        this.main.notification(msg, "success");
      } else {
        this.main.notification(reports.msg, "warning");
      }
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async resolveReport(room, sent, received, reportId){
    this.selectedReport = reportId;
    this.participants.sent.id = sent;
    this.participants.received.id = received;
    try {
      this.loading = true;
      let chatTextRes = await this.http.post("getChatLog", {report: reportId, room: room}, true);
      if(chatTextRes.success){
        if (chatTextRes.data){
          this.chatText = chatTextRes.data;
          let sentInfo = await this.user.getUserProfile(sent);
          if (sentInfo){
            this.participants.sent.name = sentInfo.username;
            this.participants.sent.tempBanCount = sentInfo.tempBanCount?sentInfo.tempBanCount:0;
          }
          let receivedInfo = await this.user.getUserProfile(received);
          if (receivedInfo){
            this.participants.received.name = receivedInfo.username;
            this.participants.received.tempBanCount = receivedInfo.tempBanCount?receivedInfo.tempBanCount:0;
          }
          UIkit.modal("#modal-reportChatText").show();
        } else {
          await this.main.notification(chatTextRes.msg, "success");
        }
      } else {
        await this.main.notification(chatTextRes.msg, "warning");
        window.location.reload();
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async doneResolve(){
    let confirm = await this.main.confirmation("Хаахдаа итгэлтэй байна уу?");
    if (confirm.value){
      let doneResolve = await this.http.post("resolveReportDone", {reportId: this.selectedReport}, true);
      if (doneResolve.success){
        window.location.reload();
      }
    }
  }

  async Analyze(){
    let confirm = await this.main.confirmation("дахин analyze хийх үү?");
    if (confirm.value){
      this.loading = true;
      let analyze = await this.http.post("chatLogAnalyze", {log: JSON.stringify(this.chatText)}, true);
      if (analyze.success){
        this.chatText = analyze.data;
        this.loading = false;
      }
    }
  }

  async createNewMessage(message, userId){
    let confirm = await Swal.fire({
      title: "Итгэлтэй байна уу",
      html:`${message} өгүүлбэрийг бүртгэх үү?`,
      showCancelButton: true,
      confirmButtonText: 'Илгээх',
      cancelButtonText: 'Цуцлах'
    });
    if (confirm.value){
      try {
        const res = await this.http.post('createMlClassifyData',{message, userId}, true);
        if(res.success){
          await this.main.notification("Амжилттай", "success");
        } else {
          await this.main.notification("Амжилтгүй", "warning");
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }

  dropDownMenu(id){
    UIkit.dropdown("#actions"+id, {pos:"bottom-right", mode:"click", offset:0}).show();
  }

  async reportAction(userId, action){
    let reqData = {
      uid: userId,
      action:action,
      reportId: this.selectedReport
    }
    let confirm = await Swal.fire({
      title: "Итгэлтэй байна уу",
      html:"Уг хэрэглэгчид "+action+" бандах үйлдэл хийхдээ итгэлтэй байна уу",
      showCancelButton: true,
      confirmButtonText: 'Илгээх',
      cancelButtonText: 'Цуцлах'
    });
    if (confirm.value){
      try {
        let reportActions = await this.http.post("resolveReportAction", reqData, true);
        if(reportActions.success){
          await this.main.notification(reportActions.msg?reportActions.msg:"Үйлдэл амжилттай хийгдлээ", "success");
          window.location.reload();
        } else {
          await this.main.notification(reportActions.msg, "warning");
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }

}
