<div class="home_container center">
    <form class="loginForm">
        <fieldset class="uk-fieldset">
            <legend class="uk-legend">ADMIN LOGIN</legend>
            <div class="uk-margin">
                <input class="uk-input" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="text" placeholder="email">
            </div>
            <div class="uk-margin">
                <input class="uk-input" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" type="password" placeholder="password">
            </div>
        </fieldset>
        <button class="uk-button uk-button-primary" (click)="login()">Login</button>
    </form>
</div>