import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MainService } from 'src/app/services/main.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import readXlsxFile from 'read-excel-file';
import Swal from "sweetalert2";
import numeral from 'numeral';
declare var $;
declare var UIkit;
@Component({
  selector: 'app-admin-incomes',
  templateUrl: './admin-incomes.component.html',
  styleUrls: ['./admin-incomes.component.scss']
})
export class AdminIncomesComponent implements OnInit {
  incomes;
  loading = false;
  constructor(
    public main: MainService,
    private http:HttpService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getIncomes()
  }

  async getIncomes(){
    if(!this.incomes){
      try{
        const datestring = Date.now().toString()
        let incomeRes = await this.http.get("getIncomes?date="+datestring,true);
        if (incomeRes.success){
          this.incomes = incomeRes.data;
        }
      } catch(ex){
        this.errorHandler.handle(ex);
      }
    }
  }

  displayIncomes(option1, option2){
    if(this.incomes){
      if(option1 == "total"){
        let select = this.incomes.total.total.find(srch=>{return srch._id == option2});
        return select?this.main.moneyformat(select.balance):0;
      } else if(option1 == "month"){
        let select = this.incomes.total.month.find(srch=>{return srch._id == option2});
        return select?this.main.moneyformat(select.balance):0;
      } else {
        let select = this.incomes.total.today.find(srch=>{return srch._id == option2});
        return select?this.main.moneyformat(select.balance):0;
      }
    } else {
      return null;
    }
  }

  resolveFailedTxn(txnId){
    Swal.fire({
      title: 'User Email оруулна уу',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Resolve',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.value) {
        let reconfirm = await this.main.confirmation("User Email нь "+result.value+" мөн үү?");
        if(reconfirm.value){
          try {
            this.loading = true;
            let req = await this.http.post("resolveFailedTxn", {email:result.value, txnId}, true);
            if(req.success){
              await this.main.notification("Амжилттай засварлалаа", "success");
              window.location.reload();
            } else {
              this.main.notification(req.msg, "warning");
            }
            this.loading = false;
          } catch(ex){
            this.loading = false;
            this.errorHandler.handle(ex);
          }
        }
      }
    })
  }

  async resolveQpay(invoiceId){
    let confirm = await this.main.confirmation("Qpay Invoice -г шалгах уу?");
    if(confirm.value){
      try {
        this.loading = true;
        let req = await this.http.post("checkPendingQpayInvoice", {invoiceId}, true);
        if(req.success){
          await this.main.notification(req.msg, "success");
          window.location.reload();
        } else {
          this.main.notification(req.msg, "warning");
        }
        this.loading = false;
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

  async uploadStatement(ev){
    ev.preventDefault();
    const json = $("#jsonStatements").val();
    const txns = JSON.parse(json);
    if (txns.length) {
      try {
        let res = await this.http.post("fromJson", txns, true);
        if(res.success){
          this.main.notification("Амжилттай", "success");
        } else {
          this.main.notification("Алдаа гарлаа", "error");
        }
      } catch(ex){
        console.log(ex)
        this.main.notification("JSON file алдаатай байна", "error");
      }
    } else {
      this.main.notification("JSON file алдаатай байна", "error");
    }
  }

}
