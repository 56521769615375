import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  srchWord;
  srchResult;
  loading = false;
  constructor(
    public main: MainService,
    private http: HttpService,
    private errorHandler:ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  async search(){
    if(this.srchWord){
      try {
        this.loading = true;
        this.srchResult = null;
        let srch = await this.http.post("profile/search", {username: this.srchWord});
        if(srch.success){
          this.srchResult = srch.data;
        } else {
          this.main.notification(srch.msg, "warning");
        }
        this.loading = false;
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

  async like(uid){
    let confirm = await this.main.confirmation("Энэ хүнийг таалагдсан жагсаалтад нэмэхдээ итгэлтэй байна уу?", "Итгэлтэй байна уу?");
    if(confirm.value){
      try{
        this.loading = true;
        let liked = await this.http.post("profile/like", {uid: uid});
        if(liked.success){
          await this.main.notification("Амжилттай нэмлээ", "success");
          this.router.navigate(["/liked"]);
        } else {
          this.main.notification(liked.msg, "warning");
        }
        this.loading = false;
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

  async block(uid){
    let confirm = await this.main.confirmation("Энэ хүнийг блок хийхдээ итгэлтэй байна уу?", "Итгэлтэй байна уу?");
    if(confirm.value){
      try{
        this.loading = true;
        let blocked = await this.http.post("profile/block", {uid: uid});
        if(blocked.success){
          await this.main.notification("Амжилттай блок хийлээ", "success");
          this.router.navigate(["/blocked"]);
        } else {
          this.main.notification(blocked.msg, "warning");
        }
        this.loading = false;
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

}
