<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <h3>Блоклосон хүмүүс</h3>
            <span *ngIf="!loading && blockedList.length < 1">Та одоогоор хэн нэгнийг блоклоогүй байна</span>
            <table *ngIf="blockedList.length > 0" class="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>Харилцагч</th>
                        <th class="uk-text-right uk-visible@s">Огноо</th>
                        <th class="uk-text-right">Блок гаргах</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let blocked of blockedList; let i=index">
                        <td><img src="{{user.myPartners[blocked.blockedProfile]&&user.myPartners[blocked.blockedProfile].avatar?main.getAvatarPath(user.myPartners[blocked.blockedProfile].avatar):'./assets/img/avatars/unknown.png'}}" alt="Avatar" class="avatar uk-visible@s" style="margin-right:10px">{{user.myPartners[blocked.blockedProfile]&&user.myPartners[blocked.blockedProfile].username?user.myPartners[blocked.blockedProfile].username:'loading ...'}}</td>
                        <td class="uk-visible@s" style="vertical-align: middle">{{main.dateFormatter(blocked.createdDate, 'YYYY-MM-DD HH:mm')}}</td>
                        <td class="uk-text-right"><button [disabled]="loading" (click)="delete(blocked._id)" class="uk-button uk-button-danger button-border-radius">Блок гаргах</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>