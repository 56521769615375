import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import Swal from "sweetalert2";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
declare var UIkit;
declare var $;
@Component({
  selector: 'app-liked',
  templateUrl: './liked.component.html',
  styleUrls: ['./liked.component.scss']
})
export class LikedComponent implements OnInit {
  likedList = [];
  profile;
  messengerProfile;
  loading = true;
  public configs;
  constructor(
    public main: MainService,
    private http: HttpService,
    private errorHandler: ErrorHandlerService,
    public user: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.profile = this.user.getUserData("profile");
    this.messengerProfile = this.user.getUserData("messengerProfile");
    this.getLiked();
  }

  ngAfterViewInit() {
    this.configs = this.main.getConfigs();
    this.main.sidebarInitSmallDisplay();
  }

  async getLiked(){
    try {
      let liked = await this.http.get("profile/getLiked");
      if(liked.success){
        this.likedList = liked.data;
        this.getPublicInfos();
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  getPublicInfos(){
    let uids = this.likedList.map(ret=>{return ret.likedProfile});
    this.user.getPublicInfos(uids);
  }

  async delete(id){
    UIkit.dropdown("#actionsDrowpdown").hide();
    let confirm = await this.main.confirmation("Та уг хүнийг таалагдсан жагсаалтаас устгахдаа итгэлтэй байна уу? Энэ хүнтэй хийсэн хувийн чат мөн устах болно");
    if (confirm.value){
      try {
        this.loading = true;
        let liked = await this.http.post("profile/delLiked", {id: id});
        if(liked.success){
          window.location.reload();
        }
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

  async privateChat(partnerId) {
    UIkit.dropdown("#actionsDrowpdown").hide();
    if (!this.configs){
      this.user.logout();
    }
    try {
      let blockedValidation = await this.http.post("profile/validateBlocked",{partner: partnerId});
      if (blockedValidation.success){
        let checkRoomAvailable = await this.http.post("chat/validateRoomByPartnerId",{partner: partnerId});
        if(checkRoomAvailable.success){
          if(checkRoomAvailable.data){
            this.router.navigate(["chat/"+checkRoomAvailable.data]);
          } else {
            let confirm = await this.main.confirmation("Чат өрөө үүсээгүй байна. Та хувийн чат өрөө түрээслэх үү? Танаас "+this.configs.prices.privateChatroom+" Qoin хасагдана, Түрээсийн хугацаа 7 хоног");
            if(confirm.value){
              let newRoom = await this.http.post("chat/newPrivateRoom",{partner: partnerId});
              if(newRoom.success){
                this.router.navigate(["chat/"+newRoom.data]);
              } else {
                this.main.notification(newRoom.msg, "warning");      
              }
            }
          }
        } else {
          this.main.notification(checkRoomAvailable.msg, "warning");
        }
      } else {
        await this.main.notification(blockedValidation.msg, "warning");
        window.location.reload();
      }
    } catch(ex){
      this.errorHandler.handle(ex);
    }
  }
}
