<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul class="uk-tab" data-uk-tab="{connect:'#incomesTabs'}">
                <li class="uk-active"><a href="">Bank Incomes</a></li>
                <li><a>Qpay Incomes</a></li>
            </ul>
            <ul id="incomesTabs" class="uk-switcher">
                <li>
                    <ul class="uk-tab" data-uk-tab="{connect:'#bankIncomesTabs'}">
                        <li class="uk-active"><a href="">Failed</a></li>
                        <li><a>Success</a></li>
                        <li><a>JSON файлаас</a></li>
                    </ul>
                    <ul id="bankIncomesTabs" class="uk-switcher">
                        <li>
                            <table *ngIf="incomes && incomes.bank && incomes.bank.failed.length" class="uk-table uk-table-divider">
                                <tbody>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                        <th>Resolve</th>
                                    </tr>
                                    <tr *ngFor="let failed of incomes.bank.failed">
                                        <td>{{main.moneyformat(failed.amount)}}</td>
                                        <td>{{failed.description}}</td>
                                        <td>{{main.dateFormatter(failed.createdAt, "YYYY-MM-DD")}}</td>
                                        <td><button (click)="resolveFailedTxn(failed._id)">resolve</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <table *ngIf="incomes && incomes.bank && incomes.bank.success.length" class="uk-table uk-table-divider">
                                <tbody>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                    </tr>
                                    <tr *ngFor="let success of incomes.bank.success">
                                        <td>{{main.moneyformat(success.amount)}}</td>
                                        <td>{{success.description}}</td>
                                        <td>{{main.dateFormatter(success.createdAt, "YYYY-MM-DD")}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li uk-grid class="uk-child-width-1-1@s">
                            <div class="uk-card uk-card-default uk-card-body">
                                <form class="uk-form-stacked" (submit)="uploadStatement($event)">
                                    <div class="uk-margin">
                                        <label>Хуулганы JSON файлаас оруулах</label>
                                        <div class="uk-margin" uk-margin>
                                            <textarea id="jsonStatements" rows="12" style="width:100%"></textarea>
                                            <br>
                                            <button class="uk-button uk-button-default" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>                            
                    <ul class="uk-tab" data-uk-tab="{connect:'#qpayIncomesTabs'}">
                        <li class="uk-active"><a href="">Pending</a></li>
                        <li><a>Paid</a></li>
                    </ul>
                    <ul id="qpayIncomesTabs" class="uk-switcher">
                        <li>
                            <table *ngIf="incomes && incomes.qpay && incomes.qpay.pending.length" class="uk-table uk-table-divider">
                                <tbody>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Invoice Id</th>
                                        <th>Profile</th>
                                        <th>Date</th>
                                        <th>Resolve</th>
                                    </tr>
                                    <tr *ngFor="let pending of incomes.qpay.pending">
                                        <td>{{main.moneyformat(pending.amount)}}</td>
                                        <td>{{pending._id}}</td>
                                        <td>{{pending.profile}}</td>
                                        <td>{{main.dateFormatter(pending.createdAt, "YYYY-MM-DD")}}</td>
                                        <td><button (click)="resolveQpay(pending._id)">resolve</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <table *ngIf="incomes && incomes.qpay && incomes.qpay.success.length" class="uk-table uk-table-divider">
                                <tbody>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                    </tr>
                                    <tr *ngFor="let success of incomes.qpay.success">
                                        <td>{{main.moneyformat(success.amount)}}</td>
                                        <td>{{success.profile}}</td>
                                        <td>{{main.dateFormatter(success.createdAt, "YYYY-MM-DD")}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>