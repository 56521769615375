import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import Swal from "sweetalert2";
declare var UIkit;
declare var $;
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit {
  urlParams;
  userDetail;
  incomes;
  outcomes;
  chatHistory;
  chatLog;
  loading = false;
  //for chatlog
  partnerName;
  chatDate;
  chatText;

  constructor(
    public main: MainService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private http: HttpService,
    private user: UserService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.urlParams = params;
      this.getUserDetails();
    });
  }

  async getUserDetails(){
    if(this.urlParams){
      if(!this.userDetail){
        try{
          let detail = await this.http.post("admin/getProfileDetail", {uid: this.urlParams.uid});
          if(detail.success){
            this.userDetail = detail.data.profile;
            this.incomes = detail.data.incomes;
            this.outcomes = detail.data.outcomes;
            this.chatHistory = detail.data.fbChatHistory;
          } else {
            this.main.notification(detail.msg, "warning");
          }
        } catch(ex){
          this.loading = false;
          this.errorHandler.handle(ex);
        }
      }
    }
  }

  async getChatLog(room, partnerName, date){
    try {
      this.partnerName = partnerName;
      this.chatDate = date;
      this.loading = true;
      let chatTextRes = await this.http.post("admin/getChatLog", {room: room});
      if(chatTextRes.success){
        this.chatText = chatTextRes.data;
        UIkit.modal("#modal-chatText").show();
      } else {
        await this.main.notification(chatTextRes.msg, "warning");
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  findMyPaticipantName(log){
    let myIndex = log.participants.findIndex(srch=>{return srch == this.userDetail.id_fb_messenger});
    return log.names[myIndex==0?1:0];
  }

  async watchDocument(userId){
    try{
      this.loading = true;
      let doc = await this.http.post("admin/getUploadedDocument", {uid:userId});
      if (doc.success){
        $("#uploadedDocumentModal").attr("src", "data:image/jpg;base64,"+doc.data.photo);
        $("#uploadedDocumentModal").attr("name", userId);
        UIkit.modal("#document-modal-onProfile").show();
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async documentRequired(userId){
    if(this.userDetail.passportRequired){
      this.main.notification("Бичиг баримтын шаардлага илгээсэн байна", "warning");
    } else if(this.userDetail.passportUploaded){
      this.main.notification("Бичиг баримтаа илгээсэн байна шалгаад баталгаажуулна уу", "warning");
    } else {
      try {
        this.loading = true;
        let confirm = await this.main.confirmation("Бичиг баримтын баталгаажуулалт хийх үү?", "Бичиг баримт");
        if (confirm.value){
          let update = await this.http.post("admin/profileUpdate", 
          {uid:userId, 
            update:JSON.stringify({
              passportRequired: true, 
              passportRequireMsg:"Иргэний үнэмлэх эсвэл төрсөний гэрчилгээний хуулбараа илгээж бичиг баримтын баримтын баталгаажуулалт хийнэ үү"
            })
          });
          if (update.success){
            window.location.reload();
          } else {
            this.loading = false;
            this.main.notification(update.msg, "warning");
          }
        }
      } catch(ex){
        this.loading = false;
        this.errorHandler.handle(ex);
      }
    }
  }

  async ban(userId){
    try {
      let confirm = await Swal.fire({
        title: "Бандах",
        html:"Бандаж буй шалтгаанаа заавал бичнэ үү!",
        input: 'text',
        inputValue: "",
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Илгээх',
        cancelButtonText: 'Цуцлах'
      });
      if (confirm.value){
        this.loading = true;
        let update = await this.http.post("admin/profileUpdate", 
        {uid:userId, 
          update:JSON.stringify({
            banned: true, 
            banReason:"Та дараах шалтгаанаар системийг дахин ашиглах эрхгүй болсон. Шалтгаан: "+confirm.value
          })
        });
        if (update.success){
          window.location.reload();
        } else {
          this.loading = false;
          this.main.notification(update.msg, "warning");
        }
      }
    }catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async disable(userId){
    try {
      let confirm = await Swal.fire({
        title: "Эрх түр хаах",
        html:"Эрх хязгаарлах болсон шалтгаанаа заавал бичнэ үү!",
        input: 'text',
        inputValue: "",
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Илгээх',
        cancelButtonText: 'Цуцлах'
      });
      if (confirm.value){
        this.loading = true;
        let update = await this.http.post("admin/profileUpdate", 
        {uid:userId, 
          update:JSON.stringify({
            active: false, 
            disabledReason:"Дараах шалтгаанаар таны систем ашиглах эрхийг түр хязгаарласан байна. Шалтгаан: "+confirm.value+". Бидэнтэй Instagram (@quizmo.mn) эсвэл бусад сувгаар холбогдож нээлгэнэ үү"
          })
        });
        if (update.success){
          window.location.reload();
        } else {
          this.loading = false;
          this.main.notification(update.msg, "warning");
        }
      }
    }catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

}
