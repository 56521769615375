import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MainService } from 'src/app/services/main.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

declare var Chart;

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  constructor(
    public main: MainService,
    private http:HttpService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }
}
