<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul class="uk-tab" data-uk-tab="{connect:'#profileTabs'}">
                <li class="uk-active"><a href="">Профайл</a></li>
                <li><a href="">Хувийн мэдээлэл</a></li>
            </ul>
            <ul id="profileTabs" class="uk-switcher">
                <li>
                    <form class="uk-form-stacked uk-width-1-1@s">
                        <div class="uk-margin">
                            <label class="uk-form-label" for="username">Username</label>
                            <div class="uk-inline uk-width-1-1@s" (click)="setUsername()">
                                <a class="uk-form-icon" style="z-index: 1" uk-icon="icon: pencil"></a>
                                <input class="uk-input" [ngClass]="{'required':!profile.username}" [(ngModel)]="profile.username" name="username" disabled id="username" type="text" placeholder="Хэрэглэгчийн нэр">
                                <span *ngIf="!profile.username" style="color:red" class="uk-form-icon uk-form-icon-flip" uk-icon="icon:  warning"></span>
                            </div>
                        </div>
                        <!-- <div class="uk-margin">
                            <label class="uk-form-label" for="messengerid">FB Messenger ID</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" [ngModel]="profile.id_fb_messenger" name="fbMessengerId" id="messengerid" type="text" disabled value="12315465487879">
                            </div>
                        </div> -->
                        <div class="uk-margin">
                            <label class="uk-form-label" for="score">Score</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" [(ngModel)]="profile.score" name="score" id="score" type="text" disabled value="200">
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="qoins">Qoin</label>
                            <div class="uk-inline uk-width-1-1@s" [routerLink]="['/qoin']">
                                <a class="uk-form-icon" style="z-index: 1" [routerLink]="['/qoin']" uk-icon="icon: cart"></a>
                                <input class="uk-input" [(ngModel)]="profile.qoins" name="qoin" disabled id="qoins" type="text" value="100">
                            </div>
                        </div>
                    </form>
                    <div class="uk-width-1-1"><button (click)="chatConfig()" class="uk-button uk-button-default uk-width-1-1">Чатын Тохиргоо</button></div>
                </li>
                <li>
                    <form class="uk-form-stacked uk-width-1-1@s">
                        <div class="uk-margin">
                            <label class="uk-form-label" for="phone">Гар утас</label>
                            <div class="uk-inline uk-width-1-1@s" (click)="setPhoneNumber()">
                                <a class="uk-form-icon" style="z-index: 1" uk-icon="icon: pencil"></a>
                                <input class="uk-input" [ngClass]="{'required':!profile.phoneNumber && !profile.phoneNumber_verified, 'warning':profile.phoneNumber && !profile.phoneNumber_verified}" disabled value="{{profile.phoneNumber?main.phoneNumberFormatter(profile.phoneNumber):null}}" name="phone" id="phone" type="tel" placeholder="Гар утасны дугаар">
                                <a style="color:orange" *ngIf="profile.phoneNumber && !profile.phoneNumber_verified"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: info"></a>
                                <a style="color:green" *ngIf="profile.phoneNumber_verified"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: check"></a>
                                <a style="color:red" *ngIf="!profile.phoneNumber"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: warning"></a>
                            </div>
                        </div>
                        <div *ngIf="profile.phone_verify_code" class="uk-margin">
                            <div style="text-align: justify;border: solid 1px rgb(235, 209, 183); background-color: rgb(250, 236, 221); padding:10px; border-radius: 5px;" class="uk-width-1-1">
                                <!-- <span>Та өөрийн бүртгүүлсэн гар утасны дугаараас <b>{{main.configs.phoneVerificationNumber}}</b> дугаарт <b>{{profile.phone_verify_code}}</b> гэсэн кодыг мессежээр илгээж утасны дугаараа баталгаажуулаарай. Та Skytel-н урьдчилсан төлбөрт дугаартай бол <br><b>{{main.configs.phoneVerificationNumberSkytel}}</b> дугаарт илгээгээрээ (Skytel-н дараа төлбөрт дугаараас баталгаажуулалт хийх боломжгүй) Мессежээ илгээсний дараа шалгах товч дээр дарж баталгаажсан эсэхийг шалгаарай, хэрэв баталгаажуулалт хийж болохгүй бол бидэнтэй мэйлээр харилцаж шалгуулаарай</span> -->
                                <span>Та өөрийн бүртгүүлсэн гар утасны дугаараас <b>{{main.configs.phoneVerificationNumbers[0]}}, {{main.configs.phoneVerificationNumbers[1]}}</b> дугааруудын аль нэгэнд <b>{{profile.phone_verify_code}}</b> гэсэн кодыг мессежээр илгээж утасны дугаараа баталгаажуулаарай. Мессежээ илгээснээс хойш 5 минутын дотор баталгаажих болно. Шалгах товч дээр дарж баталгаажсан эсэхийг шалгах боломжтой, хэрэв баталгаажуулалт хийж болохгүй бол бидэнтэй мэйлээр харилцаж шалгуулаарай</span>
                                <br>
                                <div class="uk-width-1-1" style="text-align: center;">
                                    <button (click)="checkPhoneVerification()" class="uk-button uk-button-small uk-button-primary">Шалгах</button>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="firstname">Регистрийн дугаар</label>
                            <div class="uk-inline uk-width-1-1@s" (click)="setRegisterNumber()">
                                <a class="uk-form-icon" style="z-index: 1" uk-icon="icon: pencil"></a>
                                <input class="uk-input" [ngClass]="{'required':!profile.registerNumber || profile.passportRequired || profile.passportUploaded}" disabled value="{{profile.registerNumber?profile.registerNumber:profile.passportUploaded?'Баталгаажуулалт хүлээгдэж байна':null}}" name="register" id="register" type="text" placeholder="{{profile.passportRequired?'Бичиг баримтын хуулбар илгээнэ үү':'Регистрийн дугаараа оруулна уу'}}">
                                <a style="color:red" *ngIf="!profile.registerNumber && !profile.passportUploaded || profile.passportRequired" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: warning"></a>
                                <a style="color:orange" *ngIf="profile.passportUploaded"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: info"></a>
                                <a style="color:green" *ngIf="profile.passportVerified"class="uk-form-icon uk-form-icon-flip" uk-icon="icon: check"></a>
                            </div>
                        </div>
                        <div *ngIf="!profile.passportUploaded && profile.passportRequireMsg" class="uk-margin">
                            <div style="text-align: justify;border: solid 1px rgb(235, 209, 183); background-color: rgb(250, 236, 221); padding:10px; border-radius: 5px;" class="uk-width-1-1">
                                <span>{{profile.passportRequireMsg}}</span>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="gender">Хүйс</label>
                            <div class="uk-inline uk-width-1-1@s">
                                <input class="uk-input" disabled value="{{profile.gender=='male'?'Эрэгтэй':profile.gender=='female'?'Эмэгтэй':null}}" name="gender" id="gender" type="text" placeholder="Регистрийн дугаар">
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="firthday">Төрсөн өдөр</label>
                            <div class="uk-inline uk-width-1-1@s">
                                <input class="uk-input" disabled value="{{profile.birthDate?main.dateFormatter(profile.birthDate, 'YYYY-MM-DD'):''}}" name="birthDate" id="birthdate" type="text" placeholder="Регистрийн дугаар">
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="username">Сонирхол</label>
                            <div class="uk-inline uk-width-1-1@s" (click)="interests()">
                                <a class="uk-form-icon" style="z-index: 1" uk-icon="icon: pencil"></a>
                                <input class="uk-input" value="{{profile.interests?profile.interests:null}}" name="interests" disabled id="interests" type="text" placeholder="Таны сонирхол">
                            </div>
                        </div>
                    </form>
                </li>
            </ul>
        </div>
    </div>
</div>