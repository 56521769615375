<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <ul class="uk-tab" data-uk-tab="{connect:'#adminProfilesTabs'}">
                <li class="uk-active"><a href="">Search</a></li>
                <li><a>others</a></li>
            </ul>
            <ul id="adminProfilesTabs" class="uk-switcher">
                <li uk-grid class="uk-child-width-1-1@m uk-child-width-1-2@xl">
                    <div>
                        <div class="uk-card uk-card-default uk-card-body">
                            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                <label><input class="uk-radio" type="radio" [(ngModel)]="srchOption" value="username" name="username" checked> Username</label>
                                <label><input class="uk-radio" type="radio" [(ngModel)]="srchOption" value="uid" name="uid"> UID</label>
                                <label><input class="uk-radio" type="radio" [(ngModel)]="srchOption" value="alias" name="alias"> Alias</label>
                                <label><input class="uk-radio" type="radio" [(ngModel)]="srchOption" value="phone" name="alias"> Phone</label>
                            </div>
                            <form (submit)="search()" class="uk-search uk-search-default uk-width-1-2@s">
                                <span uk-search-icon></span>
                                <input class="uk-search-input" name="search" [(ngModel)]="searchString" type="search" placeholder="Search by UID, Alias, Username...">
                            </form>
                            <button [disabled]="loading" (click)="search()" class="uk-button uk-button-default">search</button>
                        </div>
                    </div>
                </li>
                <li>                            
                    dfhdfh
                </li>
            </ul>
        </div>
    </div>
</div>