<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <div uk-grid="masonry:true">
                <div class="uk-width-1-1 uk-width-2-3@m">
                    <div uk-grid class="uk-child-width-1-1 uk-child-width-1-2@s">
                        <div>
                            <div class="uk-card uk-card-small uk-card-default uk-card-body">
                                <h3 class="uk-card-title">Чаатлаж байгаа</h3>
                                <p><b>{{dashboard && dashboard.chatOnline?dashboard.chatOnline:0}}</b> хүн байна</p>
                            </div>
                        </div>
                        <div>
                            <div class="uk-card uk-card-small uk-card-default uk-card-body">
                                <h3 class="uk-card-title">Хүлээх өрөөнд</h3>
                                <p><b>{{dashboard && dashboard.waiting?dashboard.waiting:0}}</b> хүн байна</p>
                            </div>
                        </div>
                        <div>
                            <div class="uk-card uk-card-small uk-card-default uk-card-body">
                                <h3 class="uk-card-title">Qoin үлдэгдэл <img style="width:25px; height:24px" src="./assets/img/qoin.png"></h3>
                                <p>Танд <b>{{profile && profile.qoins?profile.qoins:0}}</b> Qoin байна</p>
                                <p><button (click)="balanceRefresh()" class="refresh-button uk-button uk-button-default"><span uk-icon="refresh"></span></button></p>
                            </div>
                        </div>
                        <div *ngIf="dashboard && dashboard.luckyStar">
                            <div class="uk-card uk-card-small uk-card-default uk-card-body">
                                <h3 class="uk-card-title">Lucky Star <img style="width:30px; height:30px" src="./assets/img/lucky_star.png"></h3>
                                <p><b>{{dashboard && dashboard.luckyStar?dashboard.luckyStar.username:""}}</b></p>
                                <p class="card-description">Өнөөдөр энэ хүнтэй чаатанд тааралдаад like авч чадсан эхний 10 хүнд 5 qoin нэмэгдэх болно</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-1 uk-width-1-3@m">
                    <div>
                        <div class="uk-card uk-card-small uk-card-primary uk-card-body uk-overflow-auto">
                            <h3 class="uk-card-title">Оноогоор тэргүүлэгч</h3>
                            <table *ngIf="dashboard" class="uk-table uk-table-divider">
                                <thead>
                                    <tr>
                                        <th>Нэр</th>
                                        <th>Оноо</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let person of dashboard.topScores">
                                        <td>{{person.username}}</td>
                                        <td>{{person.score}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>