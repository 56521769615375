<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="content-padder content-background" (click)="main.mobileSideBarHide()">
    <div class="uk-section-small">
        <div class="uk-container uk-container-large">
            <h3>Чатын түүх</h3>
            <span *ngIf="!loading && chatHistory.length < 1">Танд чатын түүх үүсээгүй байна</span>
            <table *ngIf="chatHistory.length > 0" class="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>Харилцагч</th>
                        <th class="uk-visible@s">Огноо</th>
                        <th class="uk-text-right">Дэлгэрэнгүй</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let history of chatHistory">
                        <td><img src="{{history.avatar?main.getAvatarPath(history.avatar):'./assets/img/avatars/unknown.png'}}" alt="Avatar" class="avatar uk-visible@s" style="margin-right:10px">{{history.name}}</td>
                        <td class="uk-visible@s" style="vertical-align: middle">{{main.dateFormatter(history.date, 'YYYY-MM-DD HH:mm')}}</td>
                        <td class="uk-text-right"><button [disabled]="loading" class="uk-button uk-button-primary button-border-radius" (click)="readChatText(history.name, main.dateFormatter(history.date, 'YYYY-MM-DD'), history.room)">{{loading?"Loading..":"Унших"}}</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div id="modal-chatText" uk-modal>
    <div class="uk-modal-dialog">

        <button class="uk-modal-close-default" type="button" uk-close></button>

        <div class="uk-modal-header">
            <h4 class="uk-modal-title">{{partnerName}} {{chatDate}}</h4>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
            <ul *ngIf="chatText.length > 0" class="uk-list uk-list-divider">
                <li *ngFor="let txt of chatText">{{main.dateFormatter(txt.timestamp, 'HH:mm:ss')}} : <b>{{txt.username}}: </b>{{ txt.text }}</li>
            </ul>
        </div>

        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-primary uk-modal-close button-border-radius" type="button">Хаах</button>
        </div>

    </div>
</div>