import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';
declare var UIkit;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    public main:MainService
  ) { }

  public providers = [
    {name: "Messenger", logo:"./assets/img/providers/fb.png", urlText:"@quizmo.mn", url:"https://m.me/quizmo.mn", active:true},
    {name: "Telegram", logo:"./assets/img/providers/telegram.png", urlText:"@QuizmomnBot", url:"https://t.me/QuizmomnBot", active:true}
  ]

  ngOnInit(): void {
  }

  
  async contact(){
    UIkit.modal("#contact-modal").show();
  }

  async quest(){
    UIkit.modal("#quest-modal").show();
  }
  
  howToQuest(){
    window.location.href = "https://questcoin.org/createwallet.html"
  }
}
