import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';
import { HttpService } from 'src/app/services/http.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
declare var UIkit;

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss']
})
export class ChatHistoryComponent implements OnInit {
  public loading = true;
  public profile;
  public chatHistory = [];
  public partnerName;
  public chatDate;
  public chatText = [];
  constructor(
    public main: MainService,
    private user: UserService,
    private http: HttpService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.profile = this.user.getUserData();
    if (!this.profile.active){
      this.main.notification("Таны хэрэглээг түр хугацаагаар хаасан байна<br>" + (this.profile.disabledReason?this.profile.disabledReason:""), "warning");
    }
    this.getChatHistory();
  }

  ngAfterViewInit() {
    this.main.sidebarInitSmallDisplay();
  }

  async getChatHistory(){
    try {
      let historyRes = await this.http.get("profile/getChatHistory");
      if(historyRes.success){
        this.chatHistory = historyRes.data;
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }

  async readChatText(partnerName, date, roomId){
    try {
      this.partnerName = partnerName;
      this.chatDate = date;
      this.loading = true;
      let chatTextRes = await this.http.post("profile/getChatConv", {room: roomId});
      if(chatTextRes.success){
        this.chatText = chatTextRes.data;
        UIkit.modal("#modal-chatText").show();
      } else {
        await this.main.notification(chatTextRes.msg, "warning");
      }
      this.loading = false;
    } catch(ex){
      this.loading = false;
      this.errorHandler.handle(ex);
    }
  }
}
